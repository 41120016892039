export const CohLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="169"
      height="56"
      viewBox="0 0 169 56"
      fill="none"
      className="w-2/6 min-w-32 md:w-auto"
    >
      <path
        d="M37.3206 32.6042L33.5453 33.4661L38.4711 55.0434L42.2464 54.1815L37.3206 32.6042Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M36.5641 23.786L34.8842 27.2752L54.8248 36.8766L56.5047 33.3874L36.5641 23.786Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M49.1418 9.28221L29.2013 18.8836L30.8812 22.3728L50.8217 12.7714L49.1418 9.28221Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M6.85556 10.1348L3.82793 12.5492L17.6263 29.8534L20.6539 27.439L6.85556 10.1348Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M22.1316 33.5948H0V37.4674H22.1316V33.5948Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M27.8691 36.2815L14.0707 53.5857L17.0983 56.0001L30.8967 38.6959L27.8691 36.2815Z"
        fill="hsl(var(--foreground))"
      />

      <path
        d="M76.2431 11.11C78.3396 11.11 79.8301 11.9451 80.6616 13.8162H83.7941C82.7793 10.4546 80.2775 8.42845 76.2395 8.42845C71.3911 8.42845 68.0331 11.8993 68.0331 16.7937C68.0331 21.6882 71.37 25.1132 76.1973 25.1132C80.1895 25.1132 82.9379 23.0166 83.7729 19.7255H80.637C79.9816 21.4169 78.6285 22.4317 76.2395 22.4317C73.2621 22.4317 71.1903 20.0179 71.1903 16.7937C71.1903 13.5695 73.241 11.11 76.2395 11.11H76.2431Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M88.6248 13.1607H85.5805V24.8207H88.6248V13.1607Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M88.7587 8.7175H85.6016V11.5153H88.7587V8.7175Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M94.4212 13.1609H91.4437V24.8209H94.4881V19.6128C94.4881 16.8608 95.4571 16.297 96.4507 16.0503C96.8806 15.8706 97.7826 15.8706 97.9623 15.8706V13.0058C96.3626 13.027 95.0765 13.6154 94.4212 15.1483V13.1609Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M105.108 25.1592C107.906 25.1592 110.157 23.6475 110.633 20.8532H107.793C107.434 22.1852 106.327 22.7913 105.108 22.7913C103.1 22.7913 101.863 21.121 101.863 19.0033C101.863 16.8855 103.103 15.2153 105.108 15.2153C106.37 15.2153 107.317 15.8249 107.723 17.0194H110.563C110.157 14.426 107.923 12.8262 105.105 12.8262C101.701 12.8262 98.8573 15.1025 98.8573 18.9821C98.8573 22.8617 101.697 25.1627 105.105 25.1627L105.108 25.1592Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M115.777 8.7175H112.733V24.8174H115.777V8.7175Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M123.78 12.8014C120.305 12.8014 117.758 15.3949 117.758 18.9574C117.758 22.5198 120.217 25.1802 123.755 25.1802C126.62 25.1802 128.67 23.6932 129.368 21.3923H126.324C125.894 22.3401 124.904 22.7454 123.751 22.7454C122.106 22.7454 120.887 21.6178 120.661 19.8348H129.523C129.544 19.6093 129.544 19.3626 129.544 19.2498C129.523 15.7085 127.648 12.8014 123.773 12.8014H123.78ZM120.693 17.8298C120.964 16.2053 122.092 15.1024 123.758 15.1024C125.584 15.1024 126.443 16.4344 126.623 17.8298H120.693Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M144.618 25.2014C149.984 25.2014 153.075 21.276 153.075 16.7692C153.075 12.2623 149.984 8.33691 144.618 8.33691C139.252 8.33691 136.161 12.2623 136.161 16.7692C136.161 21.276 139.252 25.2014 144.618 25.2014ZM144.618 11.0185C147.909 11.0185 149.896 13.6331 149.896 16.7692C149.896 19.9053 147.913 22.5199 144.618 22.5199C141.323 22.5199 139.319 19.9053 139.319 16.7692C139.319 13.6331 141.281 11.0185 144.618 11.0185Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M159.005 15.4618H160.988V13.1608H159.005V12.8014C159.005 11.3567 159.343 10.9973 160.47 10.9973H161.034V8.72094H159.635C156.771 8.72094 155.96 10.2996 155.96 12.6675V13.1643H154.336V15.4653H155.96V24.8243H159.005V15.4618Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M78.6778 37.242H72.05V30.5645H68.8929V46.6679H72.05V39.9447H78.6778V46.6679H81.835V30.5645H78.6778V37.242Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M92.3211 40.17C92.3211 42.9678 91.3521 44.342 89.7947 44.342C88.1245 44.342 87.744 42.8973 87.744 41.2764V35.0077H84.7207V42.0657C84.7207 44.9975 85.8941 47.006 88.8926 47.006C90.6967 47.006 91.7573 46.0369 92.388 44.705V46.6677H95.3654V35.0077H92.3211V40.1735V40.17Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M110.633 34.6695C108.921 34.6695 108.04 35.4799 107.384 36.833C106.799 35.3672 105.739 34.6695 104.206 34.6695C102.494 34.6695 101.433 35.797 101.049 36.9035V35.0077H98.0716V46.6677H101.116V41.5019C101.116 39.2714 101.546 37.3299 103.279 37.3299C104.904 37.3299 105.15 38.7499 105.15 40.3955V46.6642H108.082V41.1179C108.082 38.7957 108.892 37.3299 110.224 37.3299C111.69 37.3299 112.074 38.7499 112.074 40.3955V46.6642H115.073V39.6062C115.073 36.6533 113.586 34.6695 110.63 34.6695H110.633Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M127.705 39.652C127.705 36.6075 126.172 34.6237 122.631 34.6237C119.565 34.6237 117.941 36.6322 117.582 38.5914H120.422C120.714 37.5308 121.528 36.8789 122.656 36.8789C123.917 36.8789 124.731 37.3969 124.731 38.2989C124.731 39.2926 123.942 39.4935 122.455 39.6978C120.855 39.9445 117.067 40.3074 117.067 43.4858C117.067 45.4944 118.646 46.9814 121.148 46.9814C123.29 46.9814 124.283 45.8749 124.777 44.9306H124.798V45.5402C124.798 45.8784 124.819 46.3083 124.89 46.6678H127.913C127.754 45.8996 127.708 44.6381 127.708 43.736V39.6556L127.705 39.652ZM124.773 41.2553C124.773 43.9404 123.441 44.772 122.021 44.772C120.714 44.772 120.083 44.1166 120.083 43.3273C120.083 42.4921 120.739 42.02 122.272 41.7028C123.871 41.3857 124.502 40.8924 124.773 40.3286V41.2553Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M136.725 34.6695C134.921 34.6695 133.861 35.6173 133.23 36.9704V35.0077H130.252V46.6677H133.297V41.5019C133.297 38.7041 134.29 37.3299 135.823 37.3299C137.493 37.3299 137.874 38.7499 137.874 40.3955V46.6642H140.897V39.6062C140.897 36.6533 139.724 34.6695 136.725 34.6695Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M146.693 30.5645H143.536V33.3624H146.693V30.5645Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M146.556 35.0077H143.512V46.6677H146.556V35.0077Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M153.12 31.0824L150.143 32.3897V35.0043H148.247V37.3053H150.143V42.5592C150.143 44.9941 150.932 46.777 153.931 46.7312L155.375 46.7101V44.2964H154.653C153.346 44.2964 153.12 43.507 153.12 42.1081V37.3053H155.467V35.0043H153.12V31.0824Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M165.16 35.0077C164.237 38.0734 163.289 40.8923 162.588 43.4188H162.542C161.594 40.5118 160.671 37.9606 159.702 35.0077H156.499C157.852 38.7288 159.227 42.2243 160.964 46.8932L160.671 47.7495C160.425 48.5388 160.041 48.8559 159.227 48.8559H157.986V51.1781H160.287C161.866 51.1781 162.789 50.3887 163.31 48.9898C167.19 38.1438 164.664 45.3604 168.339 35.0077H165.16Z"
        fill="hsl(var(--foreground))"
      />
      <path
        d="M25.6997 0L20.7739 21.5773L24.5492 22.4392L29.475 0.861915L25.6997 0Z"
        fill="hsl(var(--primary))"
      />
    </svg>
  );
};
