import { EfbLogo } from "@/assets/EfbLogo";
import { EfbLogoAr } from "@/assets/EfbLogo-ar";
import { CohLogo } from "@/assets/CohLogo";
import { CohLogoAr } from "@/assets/CohLogo-ar";
import { useTranslation } from "react-i18next";
import { MenuIcon } from "@/assets/icons/MenuIcon";
import { Button } from "@/components/ui/button";
import { Link, useLocation } from "@tanstack/react-router";
import { useState } from "react";
import Sidebar from "./sidebar";
import { cn } from "@/lib/utils";
import { getLoginURL } from "@/utils/auth";
import { useGetAuthenticatedUser } from "@/api/user";
import { Skeleton } from "../ui/skeleton";
import { isRTLLanguage } from "@/utils/helpers";
import { QuickDonation } from "./QuickDonation";
import { ProfileIcon } from "@/assets/icons/Profile";
import { CombinedDropdown } from "./CombinedDropdown";

type NavbarTheme = "dark" | "white";

export const AppShell = ({
  theme = "white",
  logo = "efb",
  navbarClass,
  hideLogo,
}: {
  theme?: NavbarTheme;
  logo?: "efb" | "coh";
  navbarClass: string;
  hideLogo?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { data: user, isLoading } = useGetAuthenticatedUser();
  const location = useLocation();

  return (
    <div className={cn(theme, "z-10", navbarClass)}>
      <Sidebar
        isOpen={sidebarOpen}
        toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
      />
      <nav className="white container-none z-20 mx-4 flex justify-between py-6 md:mx-6">
        {logo === "efb" && (<Link
          to="/"
          aria-label="Redirect to home page"
          className={`${hideLogo && "md:hidden"} z-20`}
        >
          {isRTLLanguage(i18n.language) ? <EfbLogoAr /> : <EfbLogo />}
        </Link>)}
        {logo === "coh" && (<Link
          to="/community-challenge"
          search={{ tab: "" }}
          aria-label="Redirect to circle of humanity"  
          className={`${hideLogo && "md:hidden"} z-20`}
        >
          {isRTLLanguage(i18n.language) ? <CohLogoAr /> : <CohLogo />}
        </Link>)}
        <div className="flex-grow"></div>
        <div className="z-20 flex items-center gap-6">
          {location.pathname === "/" && (
            <div className="hidden md:block">
              <QuickDonation />
            </div>
          )}
          {user && !isLoading && (
            <Link to="/donor-profile" search={{ page: "my-updates" }}>
              {user.profile.image?.[0]?.minio_url ? (
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-transparent">
                  <img
                    src={user.profile.image?.[0]?.minio_url}
                    alt="profile image"
                    className="h-10 w-10 rounded-full object-cover"
                  />
                </div>
              ) : (
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#C5D6C2]">
                  <ProfileIcon height={20} width={19} />
                </div>
              )}
            </Link>
          )}
          {!user && !isLoading && (
            <Link
              className="text-foreground whitespace-nowrap font-medium  underline  underline-offset-2 "
              to={getLoginURL("")}
            >
              {t("header.signIn")}
            </Link>
          )}
          {isLoading && <Skeleton className="h-8 w-8 rounded-full" />}
          <CombinedDropdown />
          <Button
            size="icon"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="bg-transparent hover:bg-transparent"
            aria-label="Open sidebar"
          >
            <MenuIcon />
          </Button>
        </div>
      </nav>
    </div>
  );
};
