import { useTranslation } from "react-i18next";
import { DocumentType } from "@/types/document";
import { getLanguageSpecificClasses } from "@/utils/animation";
import { CarouselComponent } from "./CarouselComponent";

type PartnersProps = {
  readonly Items: DocumentType[];
};

export const Partners = ({ Items }: PartnersProps) => {
  const { t, i18n } = useTranslation();

  const renderPartner = (item: DocumentType) => (
    <div
      className={`w-[190px] flex-shrink-0 cursor-pointer rounded-lg border-0 bg-transparent p-6 md:w-[280px] ${getLanguageSpecificClasses(i18n.language)}`}
      key={item.tags}
    >
      <a
        href={item.tags}
        target="_blank"
        rel="noopener noreferrer nofollow"
        aria-label="Go to partner"
      >
        <div className="me-auto flex h-[100px] w-full items-center justify-center rounded-full bg-transparent">
          <img
            src={item.minio_url}
            className="h-full w-full object-contain"
            alt="Partner logo"
          />
        </div>
      </a>
    </div>
  );

  if (!Items.length) {
    return null;
  }

  return (
    <div className="mt-6 flex w-full flex-col md:mb-4">
      <h1 className="mb-4 text-2xl font-bold md:mb-6 md:text-[32px]">
        {t("Partners.title")}
      </h1>
      <section id="press" className="">
        <CarouselComponent imagesMd={1} imagesLg={2} loop autoScroll hideArrows>
          {Items.map((item) => renderPartner(item))}
        </CarouselComponent>
      </section>
    </div>
  );
};
