import React, { useEffect, useState } from "react";
import { AppShell } from "@/components/Navbar";
import { HeaderPolicy } from "./sections/headerPolicy";
import { useTranslation, Trans } from "react-i18next";
import { useLocation } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";
import { isRTLLanguage } from "@/utils/helpers";

export const PolicyPage: React.FC = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const [hashId, setHashId] = useState<string>("");

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    setHashId(hash);
  }, [location]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      const element = document.getElementById(hash);
      if (element) {
        const isMediumScreen = window.innerWidth >= 768;
        const offset = isMediumScreen ? 120 : 300;
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    };

    if (window.location.hash) {
      handleHashChange();
    }
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const renderSidebarInfo = (sidebarInfo: string, hashId: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(sidebarInfo, "text/html");
    const items = doc.querySelectorAll("li");
    items.forEach((item) => {
      item.classList.add(
        "md:text-2xl",
        "text-lg",
        "md:mb-6",
        "md:mb-4",
        "mb-2",
        "mx-8",
        "list-decimal",
      );
      const anchor = item.querySelector("a");
      if (anchor && anchor.getAttribute("href") === `#${hashId}`) {
        item.classList.add("font-bold");
      }
    });
    return { __html: doc.body.innerHTML };
  };

  return (
    <>
      <div className="bg-foreground flex justify-between items-center px-4 h-14 md:px-[24px]">
        <div className="flex flex-row gap-1 text-white">
          {t("join.first_part")}{" "}
          <Link
            aria-label={t("join.first_part") + " " + t("join.second_part")}
            to="/community-challenge"
            search={{ tab: "" }}
          >
            <p className="font-bold text-white underline"> {t("join.second_part")}</p>
          </Link>
        </div>
        <Link
            aria-label={t("join.first_part") + " " + t("join.second_part")}
            to="/community-challenge"
            search={{ tab: "" }}
          >
          {(isRTLLanguage(i18n.language) ? <img src="/coh-logo-ar-white.svg" className="h-8" /> : <img src="/coh-logo-white.svg" className="h-8" />)}
        </Link>
      </div>
      <AppShell
        theme="white"
        navbarClass="sticky inset-0 bg-background-second"
      />
      <div className="bg-background-second flex h-[50vh]">
        <HeaderPolicy title={location.search.type} />
      </div>
      <div className="mx-4 my-8 md:mx-6 md:my-24">
        <h3 className="mb-8 text-[20px] font-bold md:mb-16 md:mt-24 md:text-[32px]">
          {t(`policies.${location.search.type}.subtitle`)}
        </h3>
        <div className="flex flex-col md:flex-row">
          <div className="mb-6 w-full md:sticky md:top-32 md:mb-0 md:h-full md:w-1/4">
            <div
              dangerouslySetInnerHTML={renderSidebarInfo(
                t(`policies.${location.search.type}.sidebarInfo`),
                hashId,
              )}
            />
          </div>
          <div className="w-full md:ml-6 md:w-3/4">
            <ul className="text-[16px] leading-8 md:text-[18px]">
              <Trans
                i18nKey={`policies.${location.search.type}.description`}
                components={{
                  li: <li className="text-lg" />,
                  ul: (
                    <ul
                      className="pl-8 rtl:pr-8"
                      style={{ listStyleType: "square" }}
                    />
                  ),
                  i: <li />,
                  p: <p className="text-lg" />,
                  strong: <strong className="text-lg" />,
                  br: <br />,
                  0: <span />,
                  b: (
                    <a
                      href="/contact"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-bold underline"
                    />
                  ),
                }}
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
