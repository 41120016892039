import { cn } from "@/lib/utils";
import {
  add,
  eachMonthOfInterval,
  endOfYear,
  format,
  isEqual,
  isPast,
  parse,
  endOfMonth,
  startOfToday,
} from "date-fns";
import { ChevronLeft, ChevronRight } from "lucide-react";
import * as React from "react";
import { buttonVariants } from "../ui/button";

function getEndOfCurrentMonth() {
  return endOfMonth(startOfToday());
}

interface MonthPickerProps {
  readonly currentMonth: Date;
  readonly onMonthChange: (newMonth: Date) => void;
}

export default function MonthPicker({
  currentMonth,
  onMonthChange,
}: MonthPickerProps) {
  const [currentYear, setCurrentYear] = React.useState(
    format(currentMonth, "yyyy"),
  );
  const firstDayCurrentYear = parse(currentYear, "yyyy", new Date());

  function eachEndofMonthOfInterval(input: {
    start: Date;
    end: Date;
  }) {
    let { start, end } = input;
    let months = eachMonthOfInterval({
      start,
      end,
    });

    return months.map((month) => endOfMonth(month));
  }

  const months = eachEndofMonthOfInterval({
    start: firstDayCurrentYear,
    end: endOfYear(firstDayCurrentYear),
  })

  function previousYear() {
    let firstDayNextYear = add(firstDayCurrentYear, { years: -1 });
    setCurrentYear(format(firstDayNextYear, "yyyy"));
  }

  function nextYear() {
    let firstDayNextYear = add(firstDayCurrentYear, { years: 1 });
    setCurrentYear(format(firstDayNextYear, "yyyy"));
  }

  function renderMonthButtons(months: Date[]) {
    return (
      <div className="grid grid-cols-3 gap-4">
        {months.map((month) => (
          <button
            key={month.toString()}
            name="day"
            className={cn(
              "inline-flex items-center justify-center rounded-md p-2 text-base font-normal ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 aria-pressed:opacity-100 ",
              isEqual(month, currentMonth) &&
                "dark bg-background text-white hover:bg-background hover:text-white focus:bg-background focus:text-white",
              !isEqual(month, currentMonth) &&
                isEqual(month, getEndOfCurrentMonth()) &&
                "text-slate-900 dark:bg-slate-800 dark:text-slate-50",
            )}
            disabled={isPast(month) || isEqual(endOfMonth(month), endOfMonth(currentMonth))}
            aria-pressed={isEqual(month, currentMonth)}
            type="button"
            onClick={() => onMonthChange(month)}
            aria-label="Choose month"
          >
            <time dateTime={format(month, "yyyy-MM-dd")}>
              {format(month, "MMMM")}
            </time>
          </button>
        ))}
      </div>
    );
  }

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="space-y-4">
          <div className="relative flex items-center justify-center pt-1">
            <div
              className="text-base font-medium"
              aria-live="polite"
              id="month-picker"
            >
              {format(firstDayCurrentYear, "yyyy")}
            </div>
            <div className="flex items-center space-x-1">
              <button
                name="previous-year"
                aria-label="Go to previous year"
                className={cn(
                  buttonVariants({ variant: "outline" }),
                  "h-7 w-7 bg-transparent p-0 hover:bg-transparent",
                  "absolute left-1",
                )}
                type="button"
                onClick={previousYear}
              >
                <ChevronLeft className="h-4 w-4" />
              </button>
              <button
                name="next-year"
                aria-label="Go to next year"
                className={cn(
                  buttonVariants({ variant: "outline" }),
                  "h-7 w-7 bg-transparent p-0 hover:bg-transparent",
                  "absolute right-1 disabled:bg-slate-100",
                )}
                type="button"
                onClick={nextYear}
              >
                <ChevronRight className="h-4 w-4" />
              </button>
            </div>
          </div>
          {renderMonthButtons(months)}
        </div>
      </div>
    </div>
  );
}
