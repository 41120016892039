import { useFormStore } from "@/contexts/paymentFlowContext";
import { Route } from "@/routes/challenge-payment.$challengeId";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ChallengePaymentContainer from "./ChallengePaymentContainer";
import { useChallenge } from "@/api/challenge";
import { DonationStepper } from "../paymentFlow";
import { Link } from "@tanstack/react-router";
import { isRTLLanguage } from "@/utils/helpers";

export const ChallengePaymentFlow = () => {
  const { t, i18n } = useTranslation();
  const { challengeId } = Route.useParams();
  const { setSelectedProgram, setSelectedChallenge, setIsChallenge } =
    useFormStore();

  const { data: challenge } = useChallenge(challengeId, {
    lang: i18n.language,
  });

  useEffect(() => {
    if (challenge) {
      setSelectedChallenge(challenge);
      setIsChallenge(true);
      setSelectedProgram(challenge.program);
    }
  }, [challenge]);

  return challenge ? (
    <>
    <div className="bg-foreground fixed left-0 right-0 top-0 flex justify-between items-center px-4 h-8 md:h-14 md:px-[24px] z-50">
        <div className="flex flex-row gap-1 text-white text-xs md:text-sm">
          {t("poweredBy.first_part")}{" "}
          <Link
            aria-label={t("poweredBy.first_part") + " " + t("poweredBy.second_part")}
            to="/"
          >
            <p className="font-bold text-white underline"> {t("poweredBy.second_part")}</p>
          </Link>
        </div>
        <Link
            aria-label={t("poweredBy.first_part") + " " + t("poweredBy.second_part")}
            to="/"
          >
          {(isRTLLanguage(i18n.language) ? <img src="/efb-logo-ar-white.svg" className="h-6 md:h-8" /> : <img src="/efb-logo-white.svg" className="h-6 md:h-8" />)}
        </Link>
      </div>
      <div className="fixed top-[32px] flex w-full flex-col overflow-hidden bg-[#CDCFC9] text-white md:hidden">
        <h2 className="block p-4 pb-8 text-lg font-bold md:hidden">
          {challenge.name}
        </h2>
      </div>
      <ChallengePaymentContainer challenge={challenge}>
        <div className="bg-background h-screen w-full overflow-y-auto rounded-2xl p-4 pb-0 md:h-full md:flex-1 md:rounded-none lg:p-6 lg:pb-0 lg:pt-6">
          <DonationStepper program={challenge.program} />
        </div>
      </ChallengePaymentContainer>
    </>
  ) : null;
};
