import { Trans, useTranslation } from "react-i18next";
import { CohLogo } from "@/assets/CohLogo";
import { CohLogoAr } from "@/assets/CohLogo-ar";
import { bgOverlayStyle } from "@/utils/helpers";
import { useLocation, useNavigate } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import i18n from "@/i18n";
import React, { useMemo } from "react";
import useChallengeFormStore from "@/store/challengeStore";
import useCurrencyStore from "@/store/currencyStore";
import { Progress } from "@/components/ui/progress";
import NumberTicker from "@/components/Helpers/NumberTicker";
import { ProfileIcon } from "@/assets/icons/Profile";
import { formatDateRange } from "@/utils/challenge";
import ChallengeStepper from "./ChallengeStepper";
import { Link } from "@tanstack/react-router";
import { isRTLLanguage } from "@/utils/helpers";
interface ChallengeCreationContainerProps {
  readonly children?: React.ReactNode;
}

export default function ChallengeCreationContainer({
  children,
}: ChallengeCreationContainerProps) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const {
    resetForm,
    currentStep,
    amountEgp,
    amountUsd,
    challengeName,
    programName,
    programImage,
    coverImage,
    date,
  } = useChallengeFormStore();
  const { currency } = useCurrencyStore();

  const navigate = useNavigate();

  const handleModalClose = () => {
    resetForm();
    if (search.response_code) {
      navigate({ to: "/" });
    } else {
      navigate({ to: search.backUrl ?? "/" });
    }
  };

  const imageUrl = useMemo(() => {
    if (coverImage?.size) {
      return bgOverlayStyle(URL.createObjectURL(coverImage));
    } else if (programImage.length) {
      return bgOverlayStyle(programImage);
    }
    return bgOverlayStyle("http://localhost:5173/ChallengeLeftPanel.png");
  }, [coverImage, programImage]);

  return (
    <main className="flex h-[calc(100vh-56px)] w-full flex-col overflow-hidden sm:flex-row">
      <div
        className="relative z-20 h-[30%] w-full sm:h-full sm:w-2/5 md:h-full lg:h-full"
        style={{
          backgroundImage: imageUrl,
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="dark flex h-16 w-full items-start justify-center sm:h-12 sm:w-12 sm:p-4 md:block">
          <a
            href="/community-challenge?tab="
            aria-label="Go to circle of humanity"
            className="z-50 hidden md:block"
          >
            {i18n.language === "ar" ? <CohLogoAr /> : <CohLogo />}
          </a>
          <div className="flex-grow"></div>
          <Button
            variant={"ghost"}
            aria-label={t("closeModal")}
            className="ring-offset-background data-[state=open]:bg-accent data-[state=open]:text-muted-foreground flex hover:bg-transparent focus:outline-none focus:ring-0 focus:ring-offset-2 disabled:pointer-events-none sm:hidden"
            onClick={handleModalClose}
          >
            <X className="sm:stroke-foreground h-6 w-6 stroke-white" />
          </Button>
        </div>
        <div className="absolute bottom-0 w-full p-4 md:p-8">
          {currentStep !== 1 && (
            <div className="flex w-full flex-col items-start gap-6">
              <div className="flex w-full flex-col items-start gap-4">
                <div className="flex flex-row items-center gap-4">
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#C5D6C2]">
                    <ProfileIcon height={20} width={19} />
                  </div>
                  <h2 className="text-white">
                    <Trans
                      i18nKey={"challenge.createdBy"}
                      values={{ person: t("challenge.me") }}
                    />
                  </h2>
                </div>
                <div className="flex flex-col items-start gap-1">
                  <h2 className="text-white">{programName}</h2>
                  <h1 className="text-light max-w-[30rem] text-3xl font-[650] leading-[120%] md:text-[32px] lg:text-5xl 2xl:text-[4rem]">
                    {challengeName}
                  </h1>
                </div>
              </div>
              <Progress
                value={Math.min(0, 100)}
                className="-mb-2 h-[4px] rounded-[5px] bg-[#FFFFFF55] rtl:scale-x-[-1] rtl:transform"
                aria-label="Donation amount progress bar"
              />
              <div className="flex w-full justify-between">
                <div>
                  <p className="text-light text-lg font-semibold md:text-xl">
                    <NumberTicker
                      key={currency}
                      value={currency === "EGP" ? amountEgp : amountUsd}
                      initialValue={0}
                    />
                  </p>
                  <p className="text-light text-sm font-light md:text-base">
                    {t("challenge.goal")}
                  </p>
                </div>
                <div className="flex flex-col items-end">
                  <p className="text-light font-semibold">
                    {formatDateRange(date)}
                  </p>
                  <p className="text-light text-sm font-light md:text-base">
                    {t("challenge.timeRange")}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {children}
    </main>
  );
}

export const CommunityChallenge: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-foreground flex justify-between items-center px-4 h-14 md:px-[24px]">
        <div className="flex flex-row gap-1 text-white">
          {t("poweredBy.first_part")}{" "}
          <Link
            aria-label={t("poweredBy.first_part") + " " + t("poweredBy.second_part")}
            to="/"
          >
            <p className="font-bold text-white underline"> {t("poweredBy.second_part")}</p>
          </Link>
        </div>
        <Link
            aria-label={t("poweredBy.first_part") + " " + t("poweredBy.second_part")}
            to="/"
          >
          {(isRTLLanguage(i18n.language) ? <img src="/efb-logo-ar-white.svg" className="h-8" /> : <img src="/efb-logo-white.svg" className="h-8" />)}
        </Link>
      </div>
      <ChallengeCreationContainer>
        <div className="bg-background h-[70%] flex-1 overflow-hidden rounded-t-2xl px-4 pt-6 sm:h-full sm:px-5 sm:pt-3">
          <ChallengeStepper />
        </div>
      </ChallengeCreationContainer>
    </>
  );
};
