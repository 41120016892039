export default {
  title: "Ensuring Primary Needs For Everyone",
  processing: "Processing",
  install: "Install",
  installText: "To enjoy a better experience, install the app.",
  StepsContainer: {
    StepHeader: {
      donation: "Donation",
      payment: "Payment",
      details: "Details",
    },
    provide: "Your generosity will help us provide",
    nearly: "nearly",
    for: "For just",
    more: "more, you can make it a full",
    round: "Would you like to round up to",
    add: "Add",
    question: "?",
    donate: "Amount",
    once: "Once",
    donation: "donation",
  },
  header: {
    signIn: "Sign In",
    aboutUs: "About Us",
    contact: "Contact",
    terms: "Terms & Conditions",
    overview: "Overview",
    updates: "Recent Activities",
    impact: "Impact",
    share: "Share",
    shareThisCause: "Share this cause",
    allPrograms: "All Programs",
    faqs: "FAQs",
  },
  home: {
    no_program:
      "No donation programs available at the moment. Please check back soon.",
    our_success: "See our successes",
    get_in_touch: "Get in touch",
  },
  footer: {
    learn_more: "Learn more",
    about: "About Circle of Humanity",
    faq: "FAQs",
    programs: "Our Causes",
    stories: "Success Stories",
    contact: "Contact",
    legal: "Legal",
    terms: "Terms of Service",
    privacy: "Privacy Policy",
    cookies: "Cookies Policy",
    copyright: "Circle of Humanity. All rights reserved.",
  },
  ProgramListing: {
    title: "Urgent Causes",
    allTypes: "All Types",
    allDonations: "All Donations",
    noPrograms: "No Causes Available at the Moment.",
    noProgramsText:
      "It looks like there are no donation causes listed right now. Want to stay updated? Enter your email to be notified when new causes are added.",
  },
  Partners: {
    title: "Our Trusted Partners",
  },
  impact: {
    title: "Our Impact to Date",
    Beneficiaries: "Families Helped",
    YearsOfService: "Years of Service to the Community",
    Donations: "Number of Donors",
    TotalMeals: "Total Meals Distributed",
  },
  homepage: {
    secondTitle:
      "We combat food insecurity and hunger by creating sustainable donation programs that provide nourishment and hope to those in need.",
    herroButtonText: "See Our Causes",
  },
  Categories: {
    title: "Causes By Beneficiaries",
    Kids: "Kids",
    "Build Tomorrow": "Build Tomorrow",
    Orphans: "Orphans",
    Convoys: "Convoys",
  },
  ProgramCard: {
    raised_label: "Raised",
    goal_label: "Goal",
    donate_now: "Donate Now",
    preview_label: "Preview",
  },
  program: {
    impact_number: "Our Impact to Date",
    faq_title: "Frequently Asked Questions",
  },
  update: {
    meal_provision: "Meals Provided",
    box_provision: "Provision Boxes Distributed",
    share: "Share",
    no_updates: "No updates available at the moment. Please check back soon.",
    today: "Today",
    yesterday: "Yesterday",
    more: "More",
  },
  information: {
    address: "Address",
    phone: "Phone",
    email: "Email",
    contactUs: "Contact Us",
    aboutUs: "About Us",
    who: "Who is EFB?",
    faq: "FAQs",
    getInTouch: "Get in touch",
    chat: "Chat to us",
    call: "Call us",
  },
  getInTouchPage: {
    title: "Let's get in touch",
  },
  aboutPage: {
    title: "What is Circle of <br/>Humanity?",
    in: "In",
    establishment: "The Egyptian Food Bank (EFB) was established",
    volunteers: "Volunteer Partners",
    meals: "Meals provided from",
    to: "to",
    transparency: "Transparency",
    transparency_description:
      "We ensure you can track your donations, understand how your donations are spent, and see their impact. ",
    collaboration: "Collaboration",
    collaboration_description:
      "We believe in the power of partnerships, working together effectively with donors, volunteers, and organizations to eliminate hunger.​",
    sustainability: "Sustainability",
    sustainability_description:
      "We focus on creating lasting solutions that promote food security and self-reliance.​",
    spirit: "Team Spirit",
    spirit_description:
      "Our collaborative spirit fuels our collective drive to make a meaningful impact.",
    passion: "Passion",
    passion_description:
      "We approach our work with unwavering passion, fueled by our deep commitment to our mission.",
    excellence: "Excellence",
    excellence_description:
      "Our commitment to excellence involves maintaining top standards, ensuring accountability, and continuously refining our systems and processes​",
    values: "Our Values",
    mentioned: "We have been mentioned in",
    awards: "Awards and Recognition",
    believe:
      "We believe in the power of collective compassion and our shared responsibility to uplift those in need.",
    everyone:
      "Do you believe that everyone deserves access to nutritious food and a life free from hunger?",
    already: "If so, you're already part of the Circle of Humanity.",
    join: "Join the circle",
    annual: "Annual Impact Highlights",
    approach: "See Our Approach",
    ourapproach: "Our Approach",
  },
  faqsPage: {
    title: "Have questions? <br/> We’ve got answers",
    question: "Still have questions?",
    contact_support: "Contact our support team for further assistance",
  },
  updates: {
    title: "Updates",
    share: "Share",
  },

  paymentState: {
    thankYou: "Thank you for making a difference!",
    donationProcessed:
      "Your donation of <bold>{{amount}}</bold> has been successfully processed. A confirmation email with your receipt has been sent to <bold>{{email}}</bold>.",
    cashDonationProcessed:
      "Your donation of <bold>{{amount}}</bold> has been successfully recorded. Our team will contact you to arrange the collection of your donation. A confirmation email with your receipt has been sent to <bold>{{email}}</bold>.",
    createAccountPrompt: "Would you like to create an account to:",
    createAccountButton: "Yes, Create an account",
    backToHome: "Back to home page",
    shareWithFriends: "Share with friends",
    impact: {
      Donations: "Donations",
      Beneficiaries: "Beneficiaries",
      "Food boxes": "Food boxes",
      "Meals provided": "Meals provided",
    },
    MoreCauses: "More Causes to Make an Impact",
    paymentFailed: "Payment failed",
    paymentFailedMessage:
      "We tried to charge your card but something went wrong. Please retry your payment again. If you still encounter any further difficulties, please contact us for assistance.",
    retryPaymentButton: "Retry Payment",
    contactSupport: "Have a question? Contact Support",
    here: "here",
    creditCard: "Credit Card",
    payInPerson: "Donate in person",
    createAccount1:
      "Keep track of all your donations, and witness the difference you make.",
    createAccount2: "Modify or pause your monthly donations.",
    createAccount3: "Start raising challenges and motivate others to donate.",
    createAccount4:
      "Download your donation receipts directly from your profile anytime.",
    bankInstallment: "Bank Installment",
    buyNowPayLater: "Buy Now, Pay Later",
    reason: "Reason",
  },
  ourPrograms: {
    title: "Our Causes",
    filter: "Beneficiary Type",
    allPrograms: "Our Causes",
    allCauses: "All Causes",
    kids: "Kids",
    health: "Health",
    sort: "Sort By",
    close: "Close to Goal",
    launched: "Just Launched",
    orphans: "Orphans",
    nurseryChildren: "Nursery Children",
    singleMothers: "Single Mothers",
    refugees: "Refugees",
    disabled: "Disabled Individuals",
    assisted: "Assisted Living",
    donationType: "Donation Type",
    allTypes: "All Types",
    zakat: "Zakat",
    sadaqah: "Sadaqah",
    jariya: "Sadaqah Jariah",
    aqeeqah: "Aqeeqah",
    filterBy: "Filter By",
    clearAll: "Clear All",
    apply: "Apply",
    notifyMe: "Notify Me",
  },
  policies: {
    privacy: {
      title: "Privacy Policy",
      subtitle: "Effective Date: 22 July 2024",
      description: `<p>Welcome to Circle of Humanity, operated by the Egyptian Food Bank (EFB). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website coh.efb.eg (the "Website"). Please read this policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.</p>
<br></br>
<p id="collect"><strong>1. Information We Collect</strong></p>
<p>a. Personal Information: We may collect personal information such as your name, email address, phone number, and address when you voluntarily submit it to us through forms on the website, such as donation forms or contact forms.</p>
<p>b. Non-Personal Information: We may collect non-personal information automatically as you navigate through the website. This may include usage details, IP addresses, and information collected through cookies and other tracking technologies.</p>
<br></br>

<p id="use"><strong>2. Use of Information</strong></p>
<p>a. We may use the information we collect, including personal information, to:<p/>
<ul>
  <li>Provide and manage the services offered through the website.</li>
  <li>Communicate with you, including responding to your inquiries and providing updates.</li>
  <li>Process donations and maintain donor records.</li>
  <li>Improve the content and functionality of the website.</li>
  <li>Comply with legal obligations.</li>
</ul>
<p>b. We will not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy or as required by law.</p>
<br></br>

<p id="disclosure"><strong>3. Disclosure of Information</strong></p>

  <p>a. We may disclose your information, including personal information, to third-party service providers only who assist us in operating the website and providing our services. These service providers are obligated to use your information only as necessary to perform their services for us.</p>
  <p>b. We may also disclose your information to comply with legal obligations, enforce our policies, or protect our rights, property, or safety or that of others.</p>

<br></br>

<p id="data"><strong>4. Data Security</strong></p>
<p>We are committed to ensuring that your information is secure. We have implemented world-class security measures to protect your personal information from unauthorized access, use, alteration, and disclosure. These measures include:</p>
<ul>
  <li>Encryption of data transmitted between the website and users.</li>
  <li>Secure storage systems and restricted access controls to safeguard stored personal information.</li>
  <li>Regular review and enhancement of our information security practices to mitigate risks.</li>
</ul>
<p>While we strive to protect your personal information, please be aware that no method of transmission over the internet or method of electronic storage is completely secure. Therefore, we cannot guarantee absolute security.</p>
<br></br>

<p id="choices"><strong>5. Your Choices</strong></p>
<p>You can choose not to provide us with certain information, but this may limit your ability to use certain features of the website.</p>
<br></br>

<p id="privacy"><strong>6. Children's Privacy</strong></p>
<p>The website is not directed to children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information.</p>
<br></br>

<p id="changesP"><strong>7. Changes to This Privacy Policy</strong></p>
<p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on the website with an updated effective date. Your continued use of the Website after the effective date constitutes your acceptance of the updated Privacy Policy.</p>
<br></br>

<p id="contactP"><strong>8. Contact Us</strong></p>
<p>If you have any questions about this Privacy Policy or our privacy practices, please contact us <b>here</b>.</p>
      `,

      sidebarInfo: `
        <ul>
          <li><a href="#collect">Information We Collect</a></li>
          <li><a href="#use">Use of Information</a></li>
          <li><a href="#disclosure">Disclosure of Information</a></li>
          <li><a href="#data">Data Security</a></li>
          <li><a href="#choices">Your Choices</a></li>
          <li><a href="#privacy">Children's Privacy</a></li>
          <li><a href="#changesP">Changes to This Privacy Policy</a></li>
          <li><a href="#contactP">Contact Us</a></li>
        </ul>
      `,
    },
    cookies: {
      title: "Cookies Policy",
      subtitle: "Effective Date: 22 July 2024",
      description: `
      <p> Welcome to Circle of Humanity, the official website of the Egyptian Food Bank. We are committed to protecting your privacy and aim to ensure transparency regarding how we use cookies on our website. By using this site, you agree to our use of cookies as outlined in this policy.</p>
<br></br>
<p id="what"><strong>1. What are Cookies?</strong></p>
<p>Cookies are small text files stored on your computer or mobile device when you visit a website. These files help improve your browsing experience, provide personalized services, and analyze usage patterns.</p>
<br></br>
<p id="how"><strong>2. How We Use Cookies</strong></p>
<p>We use cookies to enhance the functionality of the Circle of Humanity website and your user experience. Our uses of cookies may include:<p/>
<ul>
<li>Remembering Your Preferences and Settings: Such as the site language.</li>
<li>Analyzing Site Usage: To understand how visitors use our website and to improve it based on this data.</li>
<li>Improving Performance: To ensure the website operates efficiently and smoothly.</li>
<li>Targeted Advertising: To display ads that are most relevant to you.</li>
</ul>
<br></br>
<p id="types"><strong>3. Types of Cookies We Use </strong></p>
<ul>
<li>Essential  Cookies: These cookies are necessary to enable you to navigate the website and use its basic features.</li>
<li>Functional Cookies: Used to remember your preferences and enhance your experience on the website.</li>
<li>Analytical Cookies: Help us gather information about how visitors use our website, aiding us in improving its content and performance.</li>
<li>Advertising Cookies: Used to deliver personalized advertisements based on your interests.</li>
</ul>
<br></br>
<p id="managing"><strong>4. Managing Cookies</strong></p>
<p>You can control and manage cookies through your browser settings. You may choose to refuse or delete some or all cookies. Please note that disabling cookies may affect your experience on our website and you may not be able to access certain features.</p>
<br></br>
<p id="links"><strong>5. Links to External Websites</strong></p>
<p>Our website may contain links to other websites operated by third parties. We are not responsible for the content or privacy practices of these other websites. We recommend reviewing the cookie and privacy policies of any site you visit.</p>
<br></br>
<p id="changesC"><strong>6. Changes to the Cookie Policy</strong></p>
<p>We may update this Cookie Policy from time to time to reflect changes in our practices or applicable laws. We will post any changes on this page. We recommend reviewing this page regularly to stay informed about how we use cookies.</p>
<br></br>
<p id="contactC"><strong>7. Contact Us</strong></p>
<p>If you have any questions or concerns about our Cookie Policy, please contact us <b>here</b>.</p>
      `,
      sidebarInfo: `
        <ul>
          <li><a href="#what">What are Cookies?</a></li>
          <li><a href="#how">How We Use Cookies</a></li>
          <li><a href="#types">Types of Cookies We Use</a></li>
          <li><a href="#managing">Managing Cookies</a></li>
          <li><a href="#links">Links to External Sites</a></li>
          <li><a href="#changesC">Changes to the Cookie Policy</a></li>
          <li><a href="#contactC">Contact Us</a></li>
        </ul>
      `,
    },
    terms: {
      title: "Terms of Service",
      subtitle: "Effective Date: 22 July 2024",
      description: `
          <p>Welcome to Circle of Humanity, the official website of the Egyptian Food Bank. By accessing and using this website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>
          <br></br>
          <p id="acceptance"><strong>1. Acceptance of Terms</strong></p>
          <p>By accessing and using the Circle of Humanity website, you accept and agree to be bound by the terms and provisions of this agreement. In addition, when using these particular services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this service.</p>
          <br></br>
          <p id="changesT"><strong>2. Changes to Terms</strong></p>
          <p>Circle of Humanity reserves the right to modify these terms at any time. Any changes will be effective immediately upon posting to the website. Your continued use of the site following the posting of changes constitutes your acceptance of those changes.</p>
          <br></br>
          <p id="useT"><strong>3. Use of the Site</strong></p>
          <ul>
            <li>Eligibility: This website is intended for use by individuals who are 18 years of age or older. By using this website, you represent and warrant that you are at least 18 years old.</li>
            <li>User Conduct: You agree to use the site only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the site. Prohibited behavior includes:

                <li>Harassing or causing distress or inconvenience to any other user.</li>
                <li>Transmitting obscene or offensive content.</li>
                <li>Disrupting the normal flow of dialogue within the site.</li>

            </li>
          </ul>
          <br></br>
          <p id="intellectual"><strong>4. Intellectual Property Rights</strong></p>
          <ul>
            <li>Content: All content included on this site, such as text, graphics, logos, images, audio clips, and software, is the property of the Egyptian Food Bank or its content suppliers and is protected by Egyptian and international copyright laws.</li>
            <li>Trademarks: The trademarks, logos, and service marks displayed on the site are the property of the Egyptian Food Bank. Users are prohibited from using any of these marks without prior written permission from the Egyptian Food Bank.</li>
          </ul>
          <br></br>
          <p id="donations"><strong>5. Donations and Payments</strong></p>
          <ul>
            <li>Payment Processing: Donations made through the Circle of Humanity website are processed through secure third-party payment processors. By making a donation, you agree to comply with the terms and conditions of these third-party payment processors.</li>
            <li>Recurring payments are securely handled by third-party payment processors and Circle of Humanity does not store your payment data in any way whatsoever.</li>
            <li>Refund Policy: If you believe there has been an error in your donation, please contact us immediately <b>here</b>.</li>
          </ul>
          <br></br>
          <p id="privacyT"><strong>6. Privacy Policy</strong></p>
          <p>Your use of the website is also governed by our Privacy Policy. Please review our Privacy Policy for information on how we collect, use, and protect your personal information.</p>
          <br></br>
          <p id="disclaimer"><strong>7. Disclaimer of Warranties</strong></p>
          <p>This site and its components are offered for informational purposes only; Circle of Humanity shall not be responsible or liable for the accuracy, usefulness, or availability of any information transmitted or made available via the site, and shall not be responsible or liable for any error or omissions in that information.</p>
          <br></br>
          <p id="liability"><strong>8. Limitation of Liability</strong></p>
          <p>In no event shall Circle of Humanity, its affiliates, or any of their respective directors, officers, employees, agents, or content or service providers be liable for any indirect, special, incidental, consequential, or punitive damages arising from or directly or indirectly related to the use of, or the inability to use, the site or the content, materials, and functions related thereto.</p>
          <br></br>
          <p id="governing"><strong>9. Governing Law</strong></p>
          <p>These terms shall be governed by and construed in accordance with the laws of the Arab Republic of Egypt, without regard to its conflict of law principles. Any legal action or proceeding related to your access to or use of the site shall be instituted in a competent court in Egypt.</p>
          <br></br>
          <p id="contactT"><strong>10. Contact Information</strong></p>
          <p>If you have any questions about these terms and conditions, please contact us <b>here</b>.</p>
        `,
      sidebarInfo: `
        <ul>
          <li><a href="#acceptance">Acceptance of Terms</a></li>
          <li><a href="#changesT">Changes to Terms</a></li>
          <li><a href="#useT">Use of the Site</a></li>
          <li><a href="#intellectual">Intellectual Property Rights</a></li>
          <li><a href="#donations">Donations and Payments</a></li>
          <li><a href="#privacyT">Privacy Policy</a></li>
          <li><a href="#disclaimer">Disclaimer of Warranties</a></li>
          <li><a href="#liability">Limitation of Liability</a></li>
          <li><a href="#governing">Governing Law</a></li>
          <li><a href="#contactT">Contact Information</a></li>
        </ul>
      `,
    },
    cookieBannerTitle: "We value your privacy",
    cookiesBannerText:
      'We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies. See <underline>Cookie Policy</underline>',
    cookieAccept: "Accept All",
    cookieReject: "Reject All",
  },
  notFound: {
    title: "404",
    message: "Page not found!",
    description:
      "We're sorry, the page you're looking for has been removed, renamed, or unavailable. Please go back to the home page.",
    backHome: "Go to home page",
  },
  serverError: {
    title: "500",
    message: "Oops, something went wrong!",
    description:
      "We're sorry, but it looks like something went wrong on our end. Please refresh the page or come back later.",
  },
  maintenance: {
    title: "Maintenance",
    message: "We're Making Improvements!",
    description:
      "Our website is currently undergoing scheduled maintenance to serve you better. We'll be back online shortly. Please check back in a little while.",
  },
  myDonations: {
    title: "My Donations",
    active_subscriptions: "Ongoing Subscriptions",
    subscriptions: "Subscriptions",
    allMyDonations: "All donations",
    donation_info: "Donation information",
    cause: "Cause",
    type: "Type",
    amount: "Amount",
    date: "Date",
    receipt: "Receipt",
    monthlyAmount: "Monthly Amount",
    endDate: "End Date",
    modify: "Modify",
    editSubscription: "Edit Subscription",
    checkReceipt: "Check Receipt",
    subscription: "Subscription",
    oneTime: "One Time",
    seeAllCauses: "See All Causes",
    donationHistory: "My Donation history",
    pauseDonation: "Pause my donation",
    yesCancelDonation: "Yes, Cancel my donation",
    cancellationText:
      "Are you sure you want to cancel your monthly donation to {{cause}} cause ?",
    cancelledSuccessTitle: "Donation Cancelled",
    cancelledSuccess:
      "Your monthly donation to Monthly Feeding Cause has been successfully cancelled.\nThank you for your past contributions.",
    saveChanges: "Save Changes",
    cancelMySubscription: "Cancel my subscription",
    resumeMySubscription: "Resume my subscription",
    chooseFinalMonth: "Choose final donation month",
    orWriteAmount: "Or write your amount",
    editMonthlyDonation: "Edit your monthly donation",
    donationBreakdown: "Donations breakdown",
    yourYear: "Your {{year}}",
    active: "Active",
    paused: "Paused",
    cancelled: "Cancelled",
    moreCauses: "See Causes",
    similarCauses: "Similar Causes",
    status: "Status",
    causeFromGround: "Your ground updates",
    impact: "Your Impact",
    loadMore: "Load More",
  },
  donorProfile: {
    myUpdates: "Personal Impact",
    myDonations: "Donations",
    accountDetails: "Account",
    logout: "Logout",
    challenges: "Challenges",
  },
  profile: {
    firstName: "First Name",
    firstNamePlaceholder: "Enter your first name",
    lastName: "Last Name",
    lastNamePlaceholder: "Enter your last name",
    email: "Email",
    emailPlaceholder: "Enter your email",
    phoneNumber: "Phone Number",
    phoneNumberPlaceholder: "Enter your phone number",
    country: "Country",
    countryPlaceholder: "Select your country",
    city: "City",
    cityPlaceholder: "Select your city",
    preferredLanguage: "Preferred Language",
    preferredLanguagePlaceholder: "Select your preferred language",
    preferredCurrency: "Preferred Currency",
    preferredCurrencyPlaceholder: "Select your preferred currency",
    personalDetails: "Personal Details",
    languageAndCurrency: "Language & Currency",
    manageAccount: "Manage Account",
    paymentMethods: "Payment Methods",
    deleteAccount: "Delete Account",
    delete: "Delete",
    logout: "Logout",
    editPhoto: "Edit Photo",
    saveChanges: "Save Changes",
    cancel: "Cancel",
    confirm: "Confirm",
    dismiss: "Dismiss",
    doYouMind: "Do you mind telling us why?",
    send: "Send",
    confirmLogout: "Confirm Logout",
    areYouSureLogout: "Are you sure you want to logout?",
    deleteYourAccount: "Delete Your Account",
    deleteSuccess: "Your account has successfully been deleted!",
    deleteCause: "I deleted my account because...",
    hopeToSeeYou: "We hope to see you again soon!",
    returnHomepage: "Return Homepage",
    remove: "Remove",
    save: "Save",
    finish: "Finish",
    english: "English",
    arabic: "Arabic",
    egp: "EGP",
    usd: "USD",
    deleteText:
      "Are you sure you want to delete your account? Once you delete your account, it cannot be undone.<br />All of your data will be permanently deleted, including your profile information, preferences and any activity history.<br />Please take a moment to consider the implications before proceeding.",
    reason1: "I found a another food bank with better services",
    reason2: "The website doesn’t provide enough fundraisings",
    reason3: "Some services I needed were not available",
    reason4: "The information provided was not satisfactory",
    reason5: "The website was difficult to use and navigate",
    otherReasons: "Specify other reasons..",
    byDeleting: "By deleting your account, you are going to accept our ",
    terms: "Terms & Conditions",
    changes: "Your changes have been applied",
    addCard: "Add a card",
  },
  myUpdates: {
    header: "Updates from the ground",
    familiesHelped: "Families helped",
    totalMeals: "Total Meals Distributed",
    causesSupported: "Causes Supported",
    makeFirstDonation: "Make Your First Donation to Get Started",
    emptyStateMessage:
      "You haven't received any updates yet. Once you make a donation, you'll start seeing personalized stories and updates directly from the field. Explore the causes below that matter to you and make your first donation today!",
  },
  donateNow: {
    generalDonation: "General Donation",
    zakat: "Zakat",
    sadaqah: "Sadaqah",
    jariya: "Sadaqah Jariah",
    aqeeqah: "Aqeeqah",
    donationType: "Select donation type",
    donationAmout: "Select donation amount",
    donationCause: "Select a cause",
    donationFrequency: "Choose the frequency",
    monthly: "Monthly",
    once: "One time",
    enterDonationAmount: "Enter donation amount",
  },
  testimonials: {
    first_title: "What Donors Are Saying",
    second_title: "What Those We Help Are Saying",
  },
  highlightedLink: {
    all_causes: "All causes",
    see_more: "Learn More",
    all_causes_mobile: "All",
    see_more_mobile: "See Impact",
  },
  sidebar: {
    all_programs: "All Causes",
    challenge: "Create a challenge",
    about: "About Circle Of Humanity",
    faqs: "FAQs",
    contact: "Contact us",
    sadaqah: "Create Sadaqah Jariyah for loved ones",
  },
  poweredBy: {
    first_part: "Powered by",
    second_part: "The Egyptian Food Bank",
  },
  join: {
    first_part: "Join Our",
    second_part: "Circle of Humanity",
  },
  quickDonation: {
    title: "Quick Donation",
    proceedPayment: "Proceed to Payment",
    cancel: "Cancel",
  },
  yup: {
    amountInvalid: "Please enter a valid amount",
    minAmount: "The minimal amount for donation is {{currency}} {{amount}}",
    emailInvalid: "Invalid Email",
    emailRequired: "Email is required",
    fullNameAlpha:
      "Full name must contain only alphabetic characters and spaces",
    fullNameMin: "Full name must be at least 2 characters long",
    fullNameMax: "Full name must be at most 50 characters long",
    fullNameRequired: "Full name is required",
    addressRequired: "Address is required",
    cityRequired: "City is required",
    cardNumberInvalid: "Invalid Card Number",
    cardNumberRequired: "Card Number is required",
    expiryDateRequired: "Expiry Date is required",
    expiryDateInvalid: "Expiry Date cannot be in the past",
    cvvRequired: "CVV is required",
    cvvInvalid: "Invalid CVV",
    phoneNumberInvalid: "Phone number is not valid",
    phoneNumberRequired: "Phone number is required",
    collectionDateRequired: "Collection Date is required",
    firstNameRequired: "First Name is required",
    preferredLanguageMax: "Preferred language must be at most 2 characters",
    currencyMax: "Currency must be at most 3 characters",
    dateMustBeFuture: "Date must be in the future",
    dateMustBeSet: "Choose a final donation date",
    deliveryDateRequired: "Delivery date is required",
    isDeliveryRequired: "Share receival choice is required",
    quantityRequired: "Quantity is required",
    quantityInvalid: "Please enter a valid number",
    familyMembersNumberRequired: "Family members number is required",
    donationValueRequired: "Donation value is required",
    donationTypeRequired: "Donation type is required",
    causeRequired: "Cause is required",
  },
  currency: {
    egp: "EGP",
    usd: "$",
  },
  donationFlow: {
    title: "Choose donation amount",
    specialTitle: "Choose donation value",
    zakatTitle: "Calculate your Zakat",
    description:
      "Every contribution you make can provide essential support to those in need through the circle of humanity.",
    specialDescription:
      "Your generous contribution will help ensure that the meat reaches deserving families and individuals.",
    once: "One time",
    monthly: "Monthly",
    customAmount: "Or write your amount",
    placeholder: "How much would you like to donate?",
    stopDonation: "Choose final donation month",
    final: "When would you like to stop?",
    donation: "Donation Type",
    donationtype: "Select Donation Type",
    next: "Next",
    finish: "Finish",
    donateNow: "Donate Now",
    complete: "Select your payment method",
    already: "Already have an account?",
    login: "Login",
    or: "or",
    signUp: "Sign Up",
    checkout: "for a quick checkout",
    card: "Credit Card",
    egyptianDonorsWarning:
      "Please note that for your donation to go through in EGP, you <b>must</b> choose <b>EGP</b> currency",
    person: "Donate in person",
    available: "Currently available only for donors located in Egypt.",
    details: "Enter your details",
    name: "Full Name",
    fullname: "Enter your full name",
    phoneOp: "Phone Number (Optional)",
    phonemandatory: "Phone Number",
    enterPhone: "Enter your phone number (e.g., 123456789)",
    collection: "Preferred Collection Date",
    date: "Select a date for cash collection",
    expired: "Expiry Date",
    entercvv: "Enter your CVV",
    my: "MM/YY",
    cardNum: "Card Number",
    cardNumberPlaceholder: "Enter your card number",
    secured: "Secured Payment with",
    emailenter: "Enter your email",
    email: "Email",
    address: "Address",
    enterAddress: "Enter your address",
    city: "City",
    enterCity: "Select your city",
    familyMembers: "Please enter the number of family members",
    familyMembersPlaceHolder: "How many members are in your family?",
    donationValue: "Donation Value",
    donationValuePlaceholder: "Select your donation value",
    quantity: "Quantity",
    quantityPlaceholder: "How many would you like to sacrifice?",
    receiveShare: "Would you like to receive your share?",
    yesShare: "Yes, I want to receive my share",
    noShare: "No, I want it to be distributed to beneficiaries in need",
    deliveryAddress: "Delivery Address",
    deliveryAddressPlaceholder: "Please provide your delivery address in Egypt",
    deliveryDay: "Delivery Day",
    deliveryDayPlaceholder: "When would you like to receive it?",
    zakatEidText:
      "The value of Zakat al-Fitr for year {{year}} is set at {{amount}} per person.",
    globalError: "Error: Please review and correct highlighted fields",
    dedicate: "Dedicate my donation in honor of someone",
    honoree: "Honoree’s Name",
    honoreeEmail: "Honoree's Email Address",
    optional: "(optional)",
    dedicationMessage: "Dedication Message",
    enterHonoreeName: "Enter the honoree’s name",
    enterHonoreeEmail: "Enter the honoree’s email address",
    enterMessage: "Enter your message here...",
    honoree_err: "Please enter honoree name",
    email_err: "Please enter a valid email",
  },
  langSelector: {
    language: "Language",
    currency: "Currency",
  },
  challenge: {
    selectCause: "Select a cause for the challenge",
    selectCauseDescription1: "A Community Challenge allows you to create a",
    selectCauseDescription2: "collaborative fundraising campaign",
    selectCauseDescription3:
      "to support a cause you care about. Invite your friends, family, and community to contribute towards a shared goal, making a bigger impact together.",
    all: "All donations",
    sadaqah: "Sadaqah Jariyah",
    creation: "Challenge creation",
    next: "Next",
    selectGoal: "Select a goal and the time range",
    writeAmount: "Or write your amount",
    enterCustom: "Enter custom amount",
    goalTimeRange: "Challenge Time Range",
    fromTo: "From {{start}} to {{end}}",
    createdBy: "Created by {{person}}",
    describeChallenge: "Describe your challenge",
    challengeName: "Challenge name",
    namePlaceholder: "Name your challenge",
    challengeDescription: "Challenge description",
    describePlaceholder:
      "Here you can describe why is it important for you this challenge and why should be the same for the people invited",
    uploadCover: "Upload cover",
    characters: "{{top}}/{{bottom}} characters",
    timeRange: "Time Range",
    goal: "Goal",
    raised: "Raised",
    created: "Your challenge has been created!",
    createdDescription:
      "Feel free to share your challenge to your family, friends, colleagues or even through your social media!",
    createLink: "Create a link",
    copyLink: "Copy the challenge link",
    goToProfile: "Go to your profile",
    makeFirstDonation: "Make your first donation",
    manageChallenges: "Check and manage your challenges",
    description:
      "Community challenge is the space where you can create your own challenge or follow up on the other challenges created by others that you participated in.",
    more: "More info",
    myChallenges: "My Challenges",
    otherChallenges: "Other Challenges",
    step1: "Create your challenge in 3 easy steps",
    step2: "Share it with your family and friends",
    step3: "Collaborate together to help each cause",
    step4: "Join other people's challenges",
    createChallenge: "Create a challenge",
    edit: "Edit",
    closed: "Closed",
    closedAgo: "Closed {{date}}d ago",
    startIn: "Starts in {{date}}d",
    untilStart: "{{day}}d until start",
    left: "{{date}}d left",
    contributed: "{{number}} person contributed",
    contributedPlural: "{{number}} people contributed",
    deadline: "Deadline",
    backToChallenges: "Back to my challenges",
    details: "Details",
    updates: "Updates",
    supporters: "Supporters",
    challengeDetails: "Challenge Details",
    currentGoal: "Current Challenge Goal",
    closeChallenge: "Close Challenge",
    challengeUpdates: "Challenge Updates",
    postUpdate: "Post Update",
    toBePublished: "To be published",
    writeUpdate: "Write an update on your challenge...",
    endingOn: "Ending on {{date}}",
    donationWrap: "Donation wrap",
    personalDonation: "Personal donation",
    ownerUpdates: "Owner updates",
    beInspired: "Be inspired by other donors",
    supporterMessage: "Supporter message",
    noContribution: "No contribution yet",
    noUpdates: "No updates yet",
    noSupporters: "No supporters yet",
    lastWeek: "Last week",
    preferenceTitle: "Thank you message and data privacy",
    preferenceDescription:
      "Leave a thank you message to the owner that organized the challenge and make sure you decide how your information will be displayed to the other challenge participants to protect your privacy.",
    thankYouMessage: "Thank you message",
    writeMessage:
      "Write a message to the challenge owner and to the other supporters...",
    hideId: "Hide my identity and my message to the platform",
    hideAmount: "Hide my donation amount",
    confirmPreference: "Confirm preference",
    preferenceConfirmed: "Preferences confirmed!",
    preferenceConfirmedDescription:
      "Your thank you message and your data privacy preferences have been confirmed and submitted to the platform!",
    goToMyProfile: "Go to my profile",
    previewText: "Supporters will see the page like this",
    exitPreview: "Exit preview mode",
    remove: "Remove",
    dismiss: "Dismss",
    saveChanges: "Save changes",
    saved: "Changes saved successfully",
    sortBy: "Sort by",
    date: "Date",
    amount: "Amount",
    anonymous: "Anonymous",
    hidden: "Hidden",
    donateAgain: "Donate again",
    confirm: "Confirm",
    close1: "Are you sure you want to close your challenge?",
    close2:
      "Once you close your challenge you will not be able to share it or modify it anymore.This action cannot be undone.",
    close3:
      "Please take a moment to consider the implications before proceeding.",
    me: "Me",
    noDateRange: "No date range selected",
    pickDate: "Pick a date",
    today: "Today",
    yesterday: "Yesterday",
    donatedBy: "Donated by {{person}}",
    thankYou:
      "Thanks for setting <bold>{{currencySymbol}}{{amount}}</bold> as your challenge goal! Your challenge will help us provide {{mealCount}} {{roundedMealCount}} {{message}}",
    nearly: "nearly",
    challengeClosed: "Your challenge has been closed",
    challengeClosedText:
      "Thank you a lot for your support! We’ll make the best out of the money that you collected with your challenge and we’ll keep you updated on the impact you created!",
    sendCloseMessage:
      "Send a message to all the {{count}} supporters that participated",
    senClosePlcaeholder:
      "Write a message to inform the participants that you closed your challenge and thank them for their support to your challenge...",
    sendMessage: "Send message",
    backToProfile: "Back to profile",
  },
};
