import { useFormStore } from "@/contexts/paymentFlowContext";
import { SpecialProgramType } from "@/types/programs";
import React from "react";
import { Circle, CircleCheck } from "lucide-react";

interface PaymentMethodSelectorProps {
  readonly options: {
    value: string;
    label: string;
    icon: string;
  }[];
}

export default function PaymentMethodSelector({
  options,
}: PaymentMethodSelectorProps) {
  const { selectedOption, setSelectedOption, activeTab, specialType } =
    useFormStore();

  const handleKeyDown = (event: React.KeyboardEvent, value: string) => {
    if (event.key === "Enter" || event.key === " ") {
      setSelectedOption(value);
    }
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-col gap-6">
        {options
          .filter((o) =>
            specialType !== SpecialProgramType.SACRIFICE_SHARE
              ? o.value !== "Bank Installment" &&
                o.value !== "Buy Now, Pay Later"
              : true,
          )
          .map((option) =>
            activeTab === "monthly" &&
            option.value === "Donate in person" ? null : (
              <div
                key={option.value}
                className={`relative flex cursor-pointer flex-col justify-center gap-4 rounded-md border bg-[#E4E1D8] p-4 ${
                  selectedOption === option.value
                    ? "border-[#232329] p-6"
                    : "border-[#D3D0CA] p-6"
                }`}
                onClick={() => setSelectedOption(option.value)}
                onKeyDown={(e) => handleKeyDown(e, option.value)}
                tabIndex={0}
                role="button"
                aria-pressed={selectedOption === option.value}
              >
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-6">
                    {selectedOption === option.value ? (
                      <CircleCheck className="h-7 w-7 fill-[#232329] stroke-[#ECE9E3]" />
                    ) : (
                      <Circle className="stroke-[#C2B4B3]" />
                    )}
                    <span className="text-sm font-bold leading-[150%] md:text-base">
                      {option.label}
                    </span>
                  </div>
                  <img src={option.icon} alt="payment icon" />
                </div>
              </div>
            ),
          )}
      </div>
    </div>
  );
}
