import { EfbLogo } from "@/assets/EfbLogo";
import { Link, useLocation } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { footerConfig } from "@/config/config";
import { isRTLLanguage } from "@/utils/helpers";
import { EfbLogoAr } from "@/assets/EfbLogo-ar";
import { useSocialMediaInfo } from "@/api/information";
import { SocialType } from "@/types/information";
import { FacebookIcon } from "@/assets/icons/social/FacebookIcon";
import { InstagramIcon } from "@/assets/icons/social/InstagramIcon";
import { XIcon } from "@/assets/icons/social/XIcon";
import { YoutubeIcon } from "@/assets/icons/social/YoutubeIcon";
import { TiktokIcon } from "@/assets/icons/social/TiktokIcon";
import { LinkedInIcon } from "@/assets/icons/social/LinkedInIcon";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { data: socialLinks } = useSocialMediaInfo();

  const mapNameIcon = (name: string) => {
    switch (name) {
      case SocialType.FACEBOOK:
        return <FacebookIcon />;
      case SocialType.INSTAGRAM:
        return <InstagramIcon />;
      case SocialType.X:
        return <XIcon />;
      case SocialType.YOUTUBE:
        return <YoutubeIcon />;
      case SocialType.LINKEDIN:
        return <LinkedInIcon />;
      case SocialType.TIKTOK:
        return <TiktokIcon />;
    }
  };
  return !location.pathname.includes("paymentFlow") &&
    !location.pathname.includes("gratitude") &&
    !location.pathname.includes("community-challenge") &&
    !location.pathname.includes("challenge-payment") ? (
    <footer className="bg-secondary [&_li]:text-secondary-foreground [&_p]:text-secondary-foreground mt-4 px-4 py-8 md:px-6">
      <div className="dark grid grid-cols-1 gap-8 md:grid-cols-4">
        <div className="dark md:col-span-2">
          <div className="[&>svg]:scale-90">
            <Link to="/" aria-label="Go to home page">
              {isRTLLanguage(i18n.language) ? <EfbLogoAr light /> : <EfbLogo light />}
            </Link>
          </div>
        </div>
        {Object.entries(footerConfig).map(([section, items]) => (
          <div key={section}>
            <ul>
              <li className="mb-2 font-semibold">{t(`footer.${section}`)}</li>
              {items.map((entry) => (
                <li
                  className="my-1 text-sm font-light hover:underline"
                  key={`${section}-${entry.key}`}
                >
                  <Link to={entry.link} aria-label={`Go to ${entry.key}`}>
                    {t(entry.key)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="text-light mt-20 flex flex-col-reverse justify-between gap-4 p-2 md:flex-row">
        <p className="!text-light text-sm font-light">
          © {new Date().getFullYear()} {t("footer.copyright")}
        </p>
        <div className="flex gap-6">
          {socialLinks?.map((social) => (
            <a
              key={social.name}
              href={social.link}
              target="_blank"
              aria-label={`Go to ${social.name}`}
            >
              {mapNameIcon(social.name)}
            </a>
          ))}
        </div>
      </div>
    </footer>
  ) : null;
};
