import * as React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import clsx from "clsx";
import AutoScroll from "embla-carousel-auto-scroll"

interface CarouselComponentProps {
  children: React.ReactNode;
  imagesMd: number;
  imagesLg: number;
  isProgramDetail?: boolean;
  loop?: boolean;
  autoScroll?: boolean;
  hideArrows?: boolean;
}

export function CarouselComponent({
  children,
  imagesMd,
  imagesLg,
  isProgramDetail,
  loop = false,
  autoScroll = false,
  hideArrows = false
}: Readonly<CarouselComponentProps>) {
  const mdBasis = `md:basis-1/${imagesMd}`;
  const lgBasis = `lg:basis-1/${imagesLg}`;
  const plugins = autoScroll ? [AutoScroll()] : undefined;

  return (
    <Carousel
      opts={{
        align: "start",
        loop,
      }}
      hideArrows={hideArrows}
      plugins={plugins}
      className={clsx({
        "ltr:-mr-4 ltr:md:-mr-6 rtl:-ml-4 rtl:md:-ml-6": !isProgramDetail,
      })}
    >
      <CarouselContent>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return (
              <CarouselItem
                key={child.key}
                className={clsx(`${mdBasis} ${lgBasis}`, {
                  "ltr:first:ml-4 ltr:first:md:ml-6 rtl:first:mr-4 rtl:first:md:mr-6":
                    isProgramDetail,
                })}
              >
                {child}
              </CarouselItem>
            );
          }
          return null;
        })}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  );
}

