import { EfbLogo } from "@/assets/EfbLogo";
import { EfbLogoAr } from "@/assets/EfbLogo-ar";
import { Progress } from "@/components/ui/progress";
import { useTranslation } from "react-i18next";
import useCurrencyStore from "@/store/currencyStore";
import { bgOverlayStyle } from "@/utils/helpers";
import {
  formatCurrency,
  getProgramGoal,
  getProgramRaisedAmount,
  getProgramRaisedPercentage,
} from "@/utils/currency";
import NumberTicker from "@/components/Helpers/NumberTicker";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { ProgramType } from "@/types/programs";
import { useLocation, useNavigate } from "@tanstack/react-router";
import { Button } from "../ui/button";
import { X } from "lucide-react";
import i18n from "@/i18n";

interface PaymentFlowContainerProps {
  readonly program: ProgramType;
  readonly children?: React.ReactNode;
}

export default function PaymentFlowContainer({
  program,
  children,
}: PaymentFlowContainerProps) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { inputAmount, reset } = useFormStore();
  const totalDonations = useFormStore((state) => state.totalDonations);
  const { currency } = useCurrencyStore();

  if (!program) {
    return null;
  }

  const inputAmt = inputAmount ?? 0;
  let goalAmount = getProgramGoal(program);
  let collectedAmount = getProgramRaisedAmount(program) + totalDonations;

  if (inputAmt !== 0 && !search.response_code) {
    goalAmount = getProgramGoal(program);
    collectedAmount = getProgramRaisedAmount(program) + inputAmt;
  }

  const raisedPercentage = getProgramRaisedPercentage(
    program,
    inputAmount && !search.response_code ? inputAmount : 0,
  );

  const impact = (totalDonations * 100) / goalAmount;
  const navigate = useNavigate();

  const handleModalClose = () => {
    reset();
    if (search.response_code) {
      navigate({ to: "/" });
    } else {
      navigate({ to: search.backUrl ?? "/" });
    }
  };

  return (
    <main className="fixed flex h-full w-full flex-col overflow-hidden pt-[92px] sm:flex-row md:h-screen md:pt-[52px]">
      <div
        className="relative z-20 hidden h-full w-full sm:h-full sm:w-2/5 md:block"
        style={{
          background: bgOverlayStyle(program?.main_image?.minio_url),
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="dark h-16 w-full items-start justify-center sm:h-12 sm:w-12 sm:p-4">
          <a
            href="/"
            aria-label="Go to home page"
            className="z-50 hidden md:block"
          >
            {i18n.language === "ar" ? <EfbLogoAr /> : <EfbLogo />}
          </a>
          <div className="flex-grow"></div>
          <Button
            variant={"ghost"}
            aria-label={t("closeModal")}
            className="ring-offset-background data-[state=open]:bg-accent data-[state=open]:text-muted-foreground flex hover:bg-transparent focus:outline-none focus:ring-0 focus:ring-offset-2 disabled:pointer-events-none sm:hidden"
            onClick={handleModalClose}
          >
            <X className="sm:stroke-foreground h-6 w-6 stroke-white" />
          </Button>
        </div>
        <div className="absolute bottom-0 w-full p-4 md:p-8">
          <h1 className="text-light mb-4 max-w-[30rem] text-3xl font-bold md:text-5xl lg:text-5xl 2xl:text-[4rem]">
            {program?.title}
          </h1>
          {!program.is_special && (
            <>
              <Progress
                value={Math.min(raisedPercentage + impact, 100)}
                className="mb-2 h-[4px] rounded-[5px] bg-[#FFFFFF55] rtl:scale-x-[-1] rtl:transform"
                aria-label="Donation amount progress bar"
              />
              <div className="flex justify-between">
                <div>
                  <p className="text-light text-lg font-semibold md:text-xl">
                    <NumberTicker
                      key={currency}
                      value={collectedAmount + totalDonations}
                      initialValue={collectedAmount}
                    />
                  </p>
                  <p className="text-light text-sm font-light md:text-base">
                    {t("ProgramCard.raised_label")} —{" "}
                    {(raisedPercentage + impact).toFixed(1)}%
                  </p>
                </div>
                <div className="flex flex-col items-end">
                  <p className="text-light text-lg font-semibold md:text-xl">
                    {formatCurrency(goalAmount)}
                  </p>
                  <p className="text-light text-sm font-light md:text-base">
                    {t("ProgramCard.goal_label")}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {children}
    </main>
  );
}
