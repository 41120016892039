
export const EfbLogo = ({ light = false }: { light?: boolean }) => {
  const fill = light ? "#fdfafd" : "#0a5849";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="169"
      height="56"
      viewBox="0 0 607.98 169.52"
      fill="none"
      className="w-2/6 min-w-32 md:w-auto"
    >
      <g id="Layer_1-2">
        <g>
          <g>
            <path fill={fill} d="M186.94,6.17v12.93h-32.37v13.4h29.12v12.37h-29.12v13.49h33.4v12.84h-47.91V6.17h46.89Z" />
            <path fill={fill} d="M236.53,62.73c-4.4,4.9-9.88,7.35-16.42,7.35s-12.06-2.22-16.56-6.65c-4.5-4.43-6.74-10.36-6.74-17.77s2.31-13.49,6.93-18.23c4.62-4.74,9.91-7.12,15.86-7.12s10.88,2.45,14.79,7.35v-6.6h13.95v43.63c0,4.47-.78,8.43-2.33,11.91-1.55,3.47-3.63,6.2-6.23,8.19-5.15,4.09-11.23,6.14-18.23,6.14-3.72,0-7.52-.6-11.4-1.81-3.88-1.21-7.27-2.93-10.19-5.16l5.4-10.51c4.59,3.47,9.44,5.21,14.56,5.21s9.16-1.27,12.14-3.81c2.98-2.54,4.47-6.57,4.47-12.09Zm-1.95-17.63c0-4-1.12-7.15-3.35-9.44-2.23-2.29-4.99-3.44-8.28-3.44s-6.13,1.15-8.51,3.44c-2.39,2.3-3.58,5.43-3.58,9.4s1.16,7.18,3.49,9.63c2.33,2.45,5.16,3.67,8.51,3.67s6.14-1.21,8.37-3.63c2.23-2.42,3.35-5.63,3.35-9.63Z" />
            <path fill={fill} d="M270.2,90.92c-4.9,0-9.52-1.99-13.86-5.95l6.33-10.33c2.36,2.29,4.77,3.44,7.26,3.44,1.3,0,2.42-.64,3.35-1.91,.93-1.27,1.4-2.62,1.4-4.05s-6.45-18.42-19.35-50.98h14.88l12.19,31.16,12.19-31.16h14.88l-23.26,58.98c-1.36,3.41-3.49,6.06-6.37,7.95-2.88,1.89-6.09,2.84-9.63,2.84Z" />
            <path fill={fill} d="M345.55,20.31c5.83,0,11.02,2.43,15.58,7.3,4.56,4.87,6.84,10.99,6.84,18.37s-2.28,13.55-6.84,18.51c-4.56,4.96-9.89,7.44-16,7.44s-11.02-2.45-14.74-7.35v24.65h-13.95V21.15h13.95v5.58c4.28-4.28,9.33-6.42,15.16-6.42Zm-15.35,25.91c0,4.06,1.13,7.38,3.4,9.95,2.26,2.57,5.01,3.86,8.23,3.86s6.05-1.29,8.47-3.86c2.42-2.57,3.63-5.88,3.63-9.91s-1.18-7.41-3.54-10.14c-2.36-2.73-5.18-4.09-8.47-4.09s-6.06,1.35-8.33,4.05c-2.26,2.7-3.4,6.08-3.4,10.14Z" />
            <path fill={fill} d="M394.21,31.2v22.42c0,1.92,.5,3.43,1.49,4.51,.99,1.09,2.17,1.63,3.54,1.63,2.6,0,4.81-1.18,6.6-3.54l5.21,9.77c-4.34,3.97-8.92,5.95-13.72,5.95s-8.87-1.58-12.19-4.74c-3.32-3.16-4.98-7.47-4.98-12.93V31.2h-5.86v-10.05h5.86V6.17h14.05v14.98h12.09v10.05h-12.09Z" />
            <path fill={fill} d="M419.65,14.78c-1.58-1.58-2.37-3.52-2.37-5.81s.79-4.23,2.37-5.81c1.58-1.58,3.52-2.37,5.81-2.37s4.23,.79,5.81,2.37c1.58,1.58,2.37,3.52,2.37,5.81s-.79,4.23-2.37,5.81c-1.58,1.58-3.52,2.37-5.81,2.37s-4.23-.79-5.81-2.37Zm12.79,56.42h-13.95V21.15h13.95v50.05Z" />
            <path fill={fill} d="M488.17,71.2h-13.12v-6.05c-3.6,4.53-8.05,6.79-13.35,6.79s-9.75-1.54-13.35-4.61c-3.6-3.07-5.4-7.16-5.4-12.28s1.86-8.95,5.58-11.49c3.72-2.54,8.81-3.81,15.26-3.81h10.42v-.28c0-5.33-2.82-8-8.47-8-2.42,0-4.98,.48-7.67,1.44-2.7,.96-4.98,2.16-6.84,3.58l-6.23-9.02c6.57-4.77,14.08-7.16,22.51-7.16,6.08,0,11.04,1.52,14.88,4.56,3.84,3.04,5.77,7.85,5.77,14.42v31.91Zm-14.05-19.07v-2.42h-8.74c-5.58,0-8.37,1.74-8.37,5.21,0,1.8,.67,3.18,2,4.14,1.33,.96,3.24,1.44,5.72,1.44s4.67-.76,6.56-2.28c1.89-1.52,2.84-3.55,2.84-6.09Z" />
            <path fill={fill} d="M514.86,43.29v27.91h-13.95V21.15h13.95v5.58c4.22-4.28,8.96-6.42,14.23-6.42s9.69,1.86,13.26,5.58c3.57,3.72,5.35,8.68,5.35,14.88v30.42h-13.95v-28.28c0-7.69-2.82-11.54-8.47-11.54-2.79,0-5.23,1.01-7.3,3.02-2.08,2.02-3.12,4.98-3.12,8.88Z" />
            <path fill={fill} d="M184.71,103.75v12.74h-30.14v14.05h28.65v12.74h-28.65v25.49h-14.51V103.75h44.65Z" />
            <path fill={fill} d="M244.62,143.76c0,7.26-2.51,13.37-7.54,18.33-5.02,4.96-11.33,7.44-18.93,7.44s-13.91-2.48-18.93-7.44c-5.02-4.96-7.54-11.07-7.54-18.33s2.51-13.38,7.54-18.37c5.02-4.99,11.33-7.49,18.93-7.49s13.91,2.5,18.93,7.49c5.02,4.99,7.54,11.12,7.54,18.37Zm-38.89,0c0,4.09,1.16,7.43,3.49,10,2.33,2.57,5.3,3.86,8.93,3.86s6.61-1.29,8.93-3.86c2.33-2.57,3.49-5.91,3.49-10s-1.16-7.44-3.49-10.05c-2.33-2.6-5.3-3.91-8.93-3.91s-6.6,1.3-8.93,3.91c-2.33,2.61-3.49,5.95-3.49,10.05Z" />
            <path fill={fill} d="M304.99,143.76c0,7.26-2.51,13.37-7.54,18.33-5.02,4.96-11.33,7.44-18.93,7.44s-13.91-2.48-18.93-7.44c-5.02-4.96-7.54-11.07-7.54-18.33s2.51-13.38,7.54-18.37c5.02-4.99,11.33-7.49,18.93-7.49s13.91,2.5,18.93,7.49c5.02,4.99,7.54,11.12,7.54,18.37Zm-38.89,0c0,4.09,1.16,7.43,3.49,10,2.33,2.57,5.3,3.86,8.93,3.86s6.61-1.29,8.93-3.86c2.33-2.57,3.49-5.91,3.49-10s-1.16-7.44-3.49-10.05c-2.33-2.6-5.3-3.91-8.93-3.91s-6.6,1.3-8.93,3.91c-2.33,2.61-3.49,5.95-3.49,10.05Z" />
            <path fill={fill} d="M335.37,169.52c-5.92,0-11.21-2.51-15.86-7.54-4.65-5.02-6.98-11.26-6.98-18.7s2.26-13.54,6.79-18.28c4.53-4.74,9.83-7.12,15.91-7.12s11.04,2.14,14.88,6.42v-24.56h13.95v69.03h-13.95v-6.61c-3.91,4.9-8.82,7.35-14.74,7.35Zm-8.79-25.68c0,4.03,1.21,7.33,3.63,9.91,2.42,2.57,5.24,3.86,8.47,3.86s5.97-1.29,8.23-3.86c2.26-2.57,3.4-5.89,3.4-9.95s-1.13-7.44-3.4-10.14c-2.26-2.7-5.04-4.05-8.33-4.05s-6.11,1.36-8.47,4.09c-2.36,2.73-3.54,6.11-3.54,10.14Z" />
            <path fill={fill} d="M411.23,168.78h-28.47V103.75h25.3c4.4,0,8.2,.53,11.4,1.58,3.19,1.05,5.6,2.48,7.21,4.28,2.91,3.35,4.37,7.13,4.37,11.35,0,5.09-1.64,8.87-4.93,11.35-1.12,.87-1.89,1.41-2.33,1.63-.43,.22-1.21,.57-2.33,1.07,4.03,.87,7.24,2.68,9.63,5.44,2.39,2.76,3.58,6.19,3.58,10.28,0,4.53-1.55,8.53-4.65,12-3.6,4.03-9.86,6.05-18.79,6.05Zm-13.95-38.7h6.88c4.03,0,7.02-.43,8.98-1.3,1.95-.87,2.93-2.74,2.93-5.63s-.9-4.79-2.7-5.72c-1.8-.93-4.84-1.4-9.12-1.4h-6.98v14.05Zm0,26.42h9.95c4.15,0,7.27-.51,9.35-1.54,2.08-1.02,3.12-3.04,3.12-6.05s-1.1-4.99-3.3-5.95c-2.2-.96-5.72-1.44-10.56-1.44h-8.56v14.98Z" />
            <path fill={fill} d="M487.98,168.78h-13.12v-6.05c-3.6,4.53-8.05,6.79-13.35,6.79s-9.75-1.54-13.35-4.61c-3.6-3.07-5.4-7.16-5.4-12.28s1.86-8.95,5.58-11.49c3.72-2.54,8.81-3.81,15.26-3.81h10.42v-.28c0-5.33-2.82-8-8.47-8-2.42,0-4.98,.48-7.67,1.44-2.7,.96-4.98,2.16-6.84,3.58l-6.23-9.02c6.57-4.77,14.08-7.16,22.51-7.16,6.08,0,11.04,1.52,14.88,4.56,3.84,3.04,5.77,7.85,5.77,14.42v31.91Zm-14.05-19.07v-2.42h-8.74c-5.58,0-8.37,1.74-8.37,5.21,0,1.8,.67,3.18,2,4.14,1.33,.96,3.24,1.44,5.72,1.44s4.67-.76,6.56-2.28c1.89-1.52,2.84-3.55,2.84-6.09Z" />
            <path fill={fill} d="M514.68,140.87v27.91h-13.95v-50.05h13.95v5.58c4.22-4.28,8.96-6.42,14.23-6.42s9.69,1.86,13.26,5.58c3.57,3.72,5.35,8.68,5.35,14.88v30.42h-13.95v-28.28c0-7.69-2.82-11.54-8.47-11.54-2.79,0-5.23,1.01-7.3,3.02-2.08,2.02-3.12,4.98-3.12,8.88Z" />
            <path fill={fill} d="M574.12,168.78h-13.95V99.75h13.95v35.44l15.54-16.56h17.58l-19.07,20.28,19.81,29.86h-16.56l-12.74-19.16-4.56,4.74v14.42Z" />
          </g>
          <g>
            <path fill={fill} d="M100.69,67.35v56.49c0,11.68-4.48,22.3-11.8,30.26-7.54,8.18-18.08,13.54-29.89,14.32v-56.49c0-11.68,4.48-22.3,11.8-30.26,7.71-8.38,18.53-13.57,29.89-14.32Z" />
            <path fill={fill} d="M.07,.09s0,2.59,0,3.87c-.02,25.28-.31,91.03,.46,116.28,.22,7.22,2.84,14.89,6.3,21.42,9.11,17.16,29.26,27.93,45.68,25.67,0-28.43,1.5-97.42-.46-125.72C50.42,17.89,23.93-1.45,.07,.09Z" />
            <path fill="#70c171" d="M95.93,12.62v24.06c0,17.78-14.44,26.76-32.18,27.93v-24.06c0-17.81,14.41-26.76,32.18-27.93Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};
