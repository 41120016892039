import { AppShell } from "@/components/Navbar";
import { HeroSection } from "./sections/hero-section";
import SectionNavigator from "@/pages/home/sections/tabs";
import DescriptionSection from "./sections/DescriptionSection";
import MediaGalery from "./sections/MediaGalery";
import { ImpactSection } from "./sections/impact";
import { Route } from "@/routes/program.$programId";
import { useQuery } from "@tanstack/react-query";
import UpdatesSection from "./sections/updatesSection";
import { programQueryOptions } from "@/api/program";
import { useEffect, useState, useRef } from "react";
import { DonationBox } from "./sections/DonationBox";
import SmallDonationBox from "./sections/SmallDonationBox";
import { useTranslation } from "react-i18next";
import { useFormStore } from "@/contexts/paymentFlowContext";
import { Helmet } from "react-helmet-async";
import Testimonials from "../home/sections/Testimonials";
import { useLocation } from "@tanstack/react-router";
import { FAQsComponent } from "@/components/Helpers/FaqsComponent";
import config from "@/config/config";
import { ProgramPageSkeleton } from "@/pages/program/components/program-page.skeleton";
import { Link } from "@tanstack/react-router";
import { isRTLLanguage } from "@/utils/helpers";

export const ProgramPage: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { programId } = Route.useParams();
  const { search } = useLocation();

  const {
    data: program,
    isLoading,
    isError,
  } = useQuery(
    programQueryOptions(programId, {
      lang: i18n.language,
      shared: (!!search?.shared).toString(),
    }),
  );

  const setTotalDonations = useFormStore((state) => state.setTotalDonations);
  const setDonationSocket = useFormStore((state) => state.setDonationSocket);
  const [isHeaderUp, setIsHeaderUp] = useState(false);
  const [isFooterDown, setIsFooterDown] = useState(true);
  const programHeaderRef = useRef<HTMLDivElement>(null);
  const donateNowRef = useRef<HTMLDivElement>(null);
  let sections = [
    { id: "overview", labelKey: "header.overview" },
    { id: "updates", labelKey: "header.updates" },
    { id: "impact", labelKey: "header.impact" },
  ];
  if (program?.faqs.length !== 0) {
    sections.push({ id: "faqs", labelKey: "header.faqs" });
  }
  useEffect(() => {
    const viewportHeight = window.innerHeight;
    const handleScroll = () => {
      if (programHeaderRef.current) {
        const rect = programHeaderRef.current.getBoundingClientRect();
        if (rect.top <= 0) {
          setIsHeaderUp(true);
        } else {
          setIsHeaderUp(false);
        }
      }
      if (donateNowRef.current) {
        const donateRect = donateNowRef.current.getBoundingClientRect();
        if (donateRect.bottom <= viewportHeight) {
          setIsHeaderUp(false);
          setIsFooterDown(false);
        } else {
          setIsFooterDown(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window.innerHeight]);

  useEffect(() => {
    if (programId) {
      const socket = new WebSocket(`${config.socketURL}${programId}/`);

      socket.onmessage = (message) => {
        const data = JSON.parse(message.data);
        if (data.total_donations !== undefined) {
          setTotalDonations(data.total_donations);
        }
      };

      setDonationSocket(socket);

      return () => {
        socket.close();
      };
    }
  }, [programId, setDonationSocket]);

  return (
    <>
      <Helmet>
        <title>{`${program?.title} | Circle of Humanity`}</title>
        <meta name="description" content={`${program?.description_title}...`} />
        <meta
          name="keywords"
          content={`Circle of Humanity, ${program?.title}, hunger relief, donate, Egypt, humanitarian aid`}
        />
        <link
          rel="canonical"
          href={`https://coh.efb.eg/program/${program?.id}`}
        />
        <meta
          property="og:title"
          content={`${program?.title} | Circle of Humanity`}
        />
        <meta
          property="og:description"
          content={program?.description_details}
        />
        <meta
          property="og:url"
          content={`https://coh.efb.eg/program/${program?.id}`}
        />
        <meta property="og:image" content={program?.main_image.thumbnail_url} />
        <meta property="og:site_name" content="Circle of Humanity" />
      </Helmet>
      <div className="bg-foreground flex justify-between items-center px-4 h-14 md:px-[24px]">
        <div className="flex flex-row gap-1 text-white">
          {t("join.first_part")}{" "}
          <Link
            aria-label={t("join.first_part") + " " + t("join.second_part")}
            to="/community-challenge"
            search={{ tab: "" }}
          >
            <p className="font-bold text-white underline"> {t("join.second_part")}</p>
          </Link>
        </div>
        <Link
            aria-label={t("join.first_part") + " " + t("join.second_part")}
            to="/community-challenge"
            search={{ tab: "" }}
          >
          {(isRTLLanguage(i18n.language) ? <img src="/coh-logo-ar-white.svg" className="h-8" /> : <img src="/coh-logo-white.svg" className="h-8" />)}
        </Link>
      </div>

      <AppShell theme="dark" navbarClass="top-14 absolute w-full" />

      {program ? (
        <>
          {isFooterDown && (
            <SmallDonationBox
              bgColor={program?.color_third}
              title={program?.title}
              showTitle={isHeaderUp}
              program={program}
            />
          )}
          {isHeaderUp && (
            <div className="fixed bottom-0 z-[5] hidden w-full md:block">
              <DonationBox
                bgColor={program?.color_third}
                className={program.is_special ? "px-6" : "px-16"}
                program={program}
              />
            </div>
          )}
          <main className="-mb-4">
            <HeroSection
              program={program}
              isLoading={isLoading}
              isError={isError}
            />
            <div ref={programHeaderRef}>
              <SectionNavigator sections={sections} isChallenge={false}>
                <div className="mt-14 flex w-full flex-col gap-2 md:mt-24">
                  <div className="mx-[16px] md:mx-[24px]">
                    <DescriptionSection
                      description_title={program?.description_title}
                      description_details={program?.description_details}
                    />
                  </div>
                  <MediaGalery images={program?.images} />
                  <UpdatesSection
                    bgColor={program?.color_third}
                    updates={program?.updates}
                  />
                  <ImpactSection
                    NumberOfBeneficiaries={program?.families_helped || 0}
                    TotalMeals={program?.meals_distributed || 0}
                    NumberOfDonations={program?.number_donors || 0}
                    showLink={false}
                    dynamicColor={program.color_third}
                    isProgramDetail={true}
                  />
                  <Testimonials
                    testimonials={program.testimonials}
                    size={"4xl"}
                    dynamicColor={program.color_third}
                    isProgramDetail={true}
                  />
                  {program.faqs.length !== 0 && (
                    <div
                      className="mx-[16px] my-5 md:mx-[24px] md:my-8"
                      id="faqs"
                    >
                      <h2 className="mb-5 text-2xl font-bold md:mb-5 md:text-4xl">
                        {t("program.faq_title")}
                      </h2>
                      <FAQsComponent data={program.faqs} isProgram={true} />
                    </div>
                  )}
                </div>
              </SectionNavigator>
            </div>
            <div
              ref={donateNowRef}
              className="-mb-18 z-50 hidden w-full md:block"
            >
              <DonationBox
                bgColor={program?.color_third}
                className={program.is_special ? "px-6" : "px-16"}
                program={program}
              />
            </div>
            <div ref={donateNowRef} className="-mb-18">
              <SmallDonationBox
                bgColor={program?.color_third}
                title={program?.title}
                showTitle={true}
                program={program}
                className="!static"
              />
            </div>
          </main>
        </>
      ) : (
        <ProgramPageSkeleton />
      )}
    </>
  );
};
