import useUserStore from "@/store/userStore";
import config from "@/config/config";
import { redirect } from "@tanstack/react-router";

export const IsAuthenticated = async () => {
  const { token } = useUserStore.getState();
  if (!token) {
    throw redirect({
      to: "/",
    });
  }
};

export const getLoginURL = (
  redirect: string,
  base: string = window.location.href,
  register?: boolean,
) => {
  if (base.includes("gratitude")) {
    base = base.split("gratitude")[0];
  }
  return (
    config.baseURL +
    "/auth/login" +
    `?to=${base}/${redirect}` +
    (register ? "&register=true" : "")
  );
};
