import React from "react";
import { DocumentType } from "@/types/document";
import { CarouselComponent } from "@/pages/home/sections/CarouselComponent";

type MediaCarouselProps = {
  items: DocumentType[];
};

export const MediaCarousel: React.FC<MediaCarouselProps> = ({ items }) => {
  const renderMediaItem = (item: DocumentType) => (
    <div
      className="w-[190px] flex-shrink-0 cursor-pointer rounded-lg border-0 bg-transparent p-6 md:w-[280px]"
      key={item.id}
    >
      <a
        href={item.tags}
        target="_blank"
        rel="noopener noreferrer nofollow"
        className="me-auto flex h-[100px] w-full items-center justify-center rounded-full bg-transparent"
      >
        <img
          src={item.minio_url}
          className="h-full w-full object-contain"
          alt=""
        />
      </a>
    </div>
  );

  if (!items.length) {
    return null;
  }

  return (
    <div className="mt-6 flex w-full flex-col md:mb-4">
      <section id="media-carousel">
        <CarouselComponent imagesMd={2} imagesLg={4} loop autoScroll hideArrows>
          {items.map((item) => renderMediaItem(item))}
        </CarouselComponent>
      </section>
    </div>
  );
};
