import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Dispatch, SetStateAction, useState } from "react";
import { Formik, Form } from "formik";
import { useDeleteImage, useEditImage } from "@/api/profile";
import ImageCropper from "./CropModal";
import { toast } from "react-toastify";
import { ProfileIcon } from "@/assets/icons/Profile";
import { useTranslation } from "react-i18next";
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from "@/api/user"

type Props = {
  profileImage: File | undefined;
  setProfileImage: Dispatch<SetStateAction<File | undefined>>;
};

export default function ImageModal({
  profileImage,
  setProfileImage,
}: Readonly<Props>) {
  const queryClient = useQueryClient()
  const handleUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any,
  ) => {
    if (e.target.files) {
      setFieldValue("image", e.target.files[0]);
    }
  };

  const handleImageFileChange = (image: string, setFieldValue?: any) => {
    fetch(image)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "profile-image", {
          type: blob.type,
        });
        setImage(file);
        if (setFieldValue) {
          setFieldValue("image", file);
        }
      });
  };

  const updateImage = useEditImage({
    onSuccess: () => {
      setProfileImage(image);
      queryClient.invalidateQueries({ queryKey: userKeys.logged });
      toast.success("Image updated successfully", {
        position: "bottom-center",
      });
    },
    onError: () => {
      toast.error("Error updating image", {
        position: "bottom-center",
      });
    },
  }).mutate;

  const removeImage = useDeleteImage({
    onSuccess: () => {
      setProfileImage(undefined);
      queryClient.invalidateQueries({ queryKey: userKeys.logged });
      toast.success("Image removed successfully", {
        position: "bottom-center",
      });
    },
    onError: () => {
      toast.error("Error removing image", {
        position: "bottom-center",
      });
    },
  }).mutate;
  const [cropDialogOpen, setCropDialogOpen] = useState(false);
  const [image, setImage] = useState<File>();
  const { t } = useTranslation();

  return (
    <Dialog>
      <DialogTrigger className="w-full">
        <div className="flex w-full items-center justify-between gap-8 py-8 md:flex-col md:items-start md:py-0">
          {profileImage ? (
            <img
              src={URL.createObjectURL(profileImage)}
              alt="profile image"
              className="h-32 w-32 rounded-full object-cover"
            />
          ) : (
            <div className="flex h-32 w-32 items-center justify-center rounded-full bg-[#C5D6C2]">
              <ProfileIcon height={34} width={36} />
            </div>
          )}
          <Button className="w-32" variant="transparent" type="button">
            {t("profile.editPhoto")}
          </Button>
        </div>
      </DialogTrigger>
      <DialogContent className="mt-48 flex h-screen flex-col items-start justify-between overflow-auto rounded-t-2xl p-8 pb-56 md:mt-0 md:h-auto md:w-[520px] md:gap-32 md:rounded-2xl md:p-16">
        <Formik
          initialValues={{ image: profileImage }}
          onSubmit={(values) => {
            if (values.image) {
              const formData = new FormData();
              formData.append("profile[image]", values.image);
              updateImage({
                data: formData,
              });
            } else {
              toast.warn("Please select an image", {
                position: "bottom-center",
              });
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className="flex h-full w-full flex-col items-center justify-center">
              {values.image ? (
                <div className="bg-background-second h-40 w-40 overflow-hidden rounded-full">
                  <img
                    src={URL.createObjectURL(values.image)}
                    alt="profile image"
                    className="h-full w-full object-cover"
                  />
                </div>
              ) : (
                <div className="flex h-32 w-32 items-center justify-center rounded-full bg-[#C5D6C2]">
                  <ProfileIcon height={34} width={36} />
                </div>
              )}
              <div className="my-6 flex flex-col gap-4">
                <input
                  type="file"
                  name="uploadFile"
                  aria-label="uploadFile"
                  accept="image/*"
                  title=" "
                  onChange={(e) => {
                    handleUpload(e, setFieldValue);
                    setCropDialogOpen(true);
                    e.target.value = "";
                  }}
                  className="file:border-primary-foreground file:bg-background file:text-primary-foreground
                    w-full text-transparent
                    file:w-full file:rounded-[8px]
                    file:border-2 file:px-4 file:py-2 file:text-base file:font-medium
                    hover:file:bg-black hover:file:text-white "
                />
                {values.image && (
                  <ImageCropper
                    open={cropDialogOpen}
                    setOpen={setCropDialogOpen}
                    image={URL.createObjectURL(values.image)}
                    onComplete={(imagePromise) => {
                      imagePromise.then((image) => {
                        if (image) {
                          handleImageFileChange(image, setFieldValue);
                          setCropDialogOpen(false);
                        }
                      });
                    }}
                    containerStyle="relative w-full h-[300px] bg-[#333] mt-8"
                  />
                )}
                <Button size="lg" type="submit">
                  {t("profile.save")}
                </Button>
                <Button
                  size="lg"
                  variant="ghost"
                  type="button"
                  onClick={() => {
                    setFieldValue("image", undefined);
                    removeImage();
                  }}
                >
                  {t("profile.remove")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
