import { useFormStore } from "@/contexts/paymentFlowContext";
import StepHeader from "./StepHeader";
import PaymentMethodSelector from "./PaymentMethodSelector";
import StepFooter from "./stepFooter";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const LastStep = () => {
  const { nextStep } = useFormStore();
  const { t, i18n } = useTranslation();
  const options = useMemo(
    () => [
      {
        value: "Credit Card",
        label: t("paymentState.creditCard"),
        icon: "/icons/Mastercard.svg",
        formSchema: {
          email: true,
          fullName: true,
          phoneNumber: true,
          creditCardForm: true,
        },
      },
      {
        value: "Donate in person",
        label: t("paymentState.payInPerson"),
        icon: "/icons/wallet.svg",
        formSchema: {
          email: true,
          fullName: true,
          phoneNumber: true,
          address: true,
          city: true,
          collection_date: true,
        },
      },
      {
        value: "Bank Installment",
        label: t("paymentState.bankInstallment"),
        icon: "/icons/bank.svg",
        formSchema: {},
      },
      {
        value: "Buy Now, Pay Later",
        label: t("paymentState.buyNowPayLater"),
        icon: "/icons/cash.svg",
        formSchema: {},
      },
    ],
    [i18n.language],
  );

  const handleNextStep = () => {
    nextStep();
  };

  return (
    <>
      <StepHeader />
      <div className="scrollbar-hide bg-background flex h-screen w-full flex-grow flex-col gap-16 overflow-y-auto lg:max-w-[43rem]">
        <div className="flex flex-col gap-8">
          <div className="flex w-full flex-col items-start justify-start">
            <h3 className="pt-4 text-xl font-bold leading-[120%] sm:text-[32px] md:pt-16">
              {t("donationFlow.complete")}{" "}
            </h3>
          </div>
          <PaymentMethodSelector options={options} />
        </div>
      </div>
      <StepFooter nextStep={handleNextStep} />
    </>
  );
};

export default LastStep;
